import React, { useContext } from 'react';
import { mdiHome, mdiAlertCircle } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { StateContext } from '../App';
import Layout from './Layout';
import PageHeader from './PageHeader';
import IconButton from './IconButton';

const PageNotFound = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);

    return (
        <Layout>
            <PageHeader mdiIcon={mdiAlertCircle}>{t('Page Not Found')}</PageHeader>
            <p>{t('The URL you entered or the link you followed point to a page that does not exist (anymore). Click the button below to go to the homepage.')}</p>
            <IconButton mdiIcon={mdiHome} variant="primary" route={`/${locale}/`}>{t('Home')}</IconButton>
        </Layout>
    )
}
  
export default PageNotFound;
