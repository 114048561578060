import { useTranslation } from 'react-i18next';

const FormattedNumber = (props) => {
    
    const { i18n } = useTranslation();

    if (props.children === null) {
        return '-';
    }

    const locale = props.locale ?? i18n.language;
    const numberInput = parseFloat(props.children);
    
    
    return numberInput.toLocaleString(locale);
}
  
export default FormattedNumber;
