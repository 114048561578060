import React, { useContext } from 'react';
import { mdiLogin, mdiAlertCircle } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { StateContext } from '../App';
import { UserAccountContext } from '../App';
import { OIDC_URL_LOGIN } from '../const';
import Layout from './Layout';
import PageHeader from './PageHeader';
import IconButton from './IconButton';

const LoginFailure = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);
    const userAccount = useContext(UserAccountContext);

    if(userAccount) {
        window.location.replace(OIDC_URL_LOGIN);
    }
    else {
        return (
            <Layout>
                {JSON.stringify(userAccount)}
                <PageHeader mdiIcon={mdiAlertCircle}>{t('Login Failed')}</PageHeader>
                <p>{t('The login provider could not log you in. Please try again. If the problem persists, consider clearing your cookies.')}</p>
                <IconButton mdiIcon={mdiLogin} variant="primary" route={`/${locale}/login`}>{t('Login')}</IconButton>
            </Layout>
        );
    }
}
  
export default LoginFailure;
