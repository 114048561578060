import React, { useContext } from 'react';
import { UserAccountContext } from '../App';
    
const Avatar = () => {
    const userAccount = useContext(UserAccountContext);
    
    // Taken from http://jsfiddle.net/tex2jydf/
    const stringToColour = function(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let colour = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            const temp = '00' + value.toString(16);
            colour += temp.substring(temp.length - 2, temp.length);
        }

        return colour;
    }    

    const getInitials = (name) => {
        return name.split(' ').map((n)=>n[0]).join('').substring(0, 2);
    }

    if(userAccount.picture) {
        return (
            <div className="avatar" style={{ backgroundImage: 'url(' + userAccount.picture + ')' }}></div>
        );
    }
    else {
        return (
            <div className="avatar" style={{ backgroundColor: stringToColour(userAccount.email ?? '') }}>
                <div className="initials">{getInitials(userAccount.name ?? '')}</div>
            </div>
        );
    }
}

export default Avatar;
