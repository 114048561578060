import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import { mdiLinkVariant } from '@mdi/js';

import ResultRowHeader from './ResultRowHeader'
import ResultRow from './ResultRow'
import IconButton from '../IconButton';
import LoadingSpinner from '../LoadingSpinner.js';
import FormattedNumber from '../FormattedNumber';

const paginationMargin = 5;

const updatePagination = (currentPage, lastPage) => {
    let firstPageToRender = currentPage - paginationMargin;
    if (firstPageToRender < 1) {
        firstPageToRender = 1;
    }
    let lastPageToRender = currentPage + paginationMargin;
    if (lastPageToRender > lastPage) {
        lastPageToRender = lastPage;
    }

    let paginationItems = [];
    for (let number = firstPageToRender; number <= lastPageToRender; number++) {
        paginationItems.push(number);
    }

    return paginationItems;
}

const ResultBody = (props) => {
    const { t } = useTranslation();
    const [paginationItems, setPaginationItems] = useState([]);

    const resultCount = props.data?.count ?? 0;
    const lastPage = Math.ceil(resultCount / props.itemsPerPage);

    useEffect(() => {
        if(props.data === null || !props.data.result) {
            setPaginationItems([]);
        }
        else {
            const items = updatePagination(props.currentPage, lastPage);
            setPaginationItems(items);
        }
    }, [props.currentPage, lastPage]);

    if(props.data === null || !props.data.result) {
        return (<LoadingSpinner />);
    }

    const result = props.data.result;
    if(!Array.isArray(result)) {
        result = [];
    }

    if(result) {
        let columns = props.columns;
        if(!columns || !Array.isArray(columns)) {
            columns = [];
        }

        const prevPage = props.currentPage > 1 ? props.currentPage - 1 : null;
        const nextPage = props.currentPage < lastPage ? props.currentPage + 1 : null;

        let message = '';
        if(props.data.success === false) {
            message = props.data.message;
        }
        else {
            // Success, but zero result
            message = props.noResults ?? t('No results found.');
        }

        let hasCheckbox = false;
        for(let i = 0; i < columns.length; i++) {
            if(columns[i].type === 'checkbox') {
                hasCheckbox = true;
                break;
            }
        }

        return result.length === 0
            ? <div className="area infoBox">{message}</div>
            : <>
                <div className={'resultTable' + (props.tableBusy ? ' busy' : '')}>
                    <div className="table-responsive w-100">
                        <form>
                            <table className="table table-bordered table-striped resultTable">
                                <thead>
                                    <tr>
                                        {
                                            columns.map((column, i) => <ResultRowHeader
                                                column={column}
                                                key={i}
                                                updateSort={props.updateSort}
                                                sortingColumn={props.sortingColumn}
                                                sortingOrderAscending={props.sortingOrderAscending}
                                                selectAllCheckboxStatus={props.selectAllCheckboxStatus}
                                                updateSelectAll={props.updateSelectAll}
                                            />)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    { result.map((row, i) =>
                                        <ResultRow
                                            row={row}
                                            columns={columns}
                                            key={i}
                                            rowCheckboxStatus={props.checkboxStatus[row.id] ?? false}
                                            updateCheckboxStatus={props.updateCheckboxStatus}
                                            setSelectAllCheckboxStatus={props.setSelectAllCheckboxStatus}
                                        />
                                    ) }
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>

                <div className="row gy-4 resultTableFooter">
                    <div className="col-xl-4">
                        {hasCheckbox && props.bulkActions &&
                            <div className="bulkActions">
                                <div className="caption">
                                    {t('With selected ({{num}}):', { num: props.countCheckedCheckboxes })}
                                </div>
                                {
                                    props.bulkActions.length > 1
                                        ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                    {t('Choose action')}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {props.bulkActions.map((item, i) => {
                                                        return <Dropdown.Item disabled={!props.countCheckedCheckboxes} eventKey={item.key} key={i} onClick={() => {
                                                            if(typeof(item.onClick) === 'function') {
                                                                item.onClick();
                                                            }
                                                        }}>{item.caption}</Dropdown.Item>
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        :
                                        <IconButton disabled={!props.countCheckedCheckboxes} mdiIcon={mdiLinkVariant} onClick={() => { props.bulkActions[0].onClick() }}>{t('Show download info')}</IconButton>
                                }
                            </div>
                        }
                    </div>
                    <div className="col-xl-4">
                        { lastPage > 1 &&
                            <div className="resultPagination">
                                <Pagination>
                                    <Pagination.First onClick={() => { return props.updateCurrentPage(1) }} disabled={props.currentPage === 1} />
                                    <Pagination.Prev onClick={() => { return props.updateCurrentPage(prevPage) }} disabled={prevPage === null} />
                                    {
                                        paginationItems.map((number) => {
                                            return <Pagination.Item
                                                        key={number}
                                                        onClick={() => {
                                                            if(props.currentPage !== number) {
                                                                props.updateCurrentPage(number); }
                                                            }
                                                        }       
                                                        active={number === props.currentPage}
                                                    >
                                                        {number}
                                                    </Pagination.Item>
                                        })
                                    }
                                    <Pagination.Next onClick={() => { return props.updateCurrentPage(nextPage) }} disabled={nextPage === null} />
                                    <Pagination.Last onClick={() => { return props.updateCurrentPage(lastPage) }} disabled={props.currentPage === lastPage} />
                                </Pagination>
                            </div>
                        }
                    </div>
                    <div className="col-xl-4">
                        <div className="resultPageInfo">
                            <FormattedNumber>{resultCount}</FormattedNumber> { resultCount === 1 ? t('result') : t('results')} {t('found')}
                        </div>
                    </div>
                </div>
            </>
    }
}

export default ResultBody;
