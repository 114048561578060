import React  from 'react';
import Form from 'react-bootstrap/Form';

const ResultCheckbox = (props) => {
    const handleClick = () => {
        props.updateCheckboxStatus(props.rowId, !props.rowCheckboxStatus);
        if(props.rowCheckboxStatus === true) {
            props.setSelectAllCheckboxStatus(false);
        }
    }

    return (
        <Form.Check 
            name="classification"
            type="checkbox"
            value={props.rowId}
            id={'resultTableCheckbox-' + props.rowId}
            checked={props.rowCheckboxStatus}
            onChange={handleClick}
            aria-label={props.rowName}
        />
    );
}
  
export default ResultCheckbox;
