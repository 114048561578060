import React  from 'react';
import { t } from 'i18next';
import Form from 'react-bootstrap/Form';
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

const ResultRowHeader = (props) => {
    const handleCheckboxClick = () => {
        props.updateSelectAll(true);
    }

    let classes = ['column-' + props.column.id];
    if(props.column.type === 'checkbox') {
        classes.push('checkboxHeader');
    }
    else if(props.column.type === 'actions') {
        classes.push('actionsHeader');
        classes.push('actionsCount-' + (props.column.actions.length));
    }

    if(props.column.align === 'right') {
        classes.push('text-align-right');
    }
    if(props.column.class) {
        classes.push(props.column.class);
    }

    let htmlStyle= {};
    if(props.column.width) {
        htmlStyle.width = props.column.width;
    }

    let onClick = null;
    if(props.column.type !== 'checkbox' && props.column.type !== 'actions') {
        onClick = () => {
            props.updateSort(props.column.id);
        }
    }

    let cell = null;
    if(props.column.type === 'checkbox') {
        cell =  <Form.Check 
            name="classification"
            type="checkbox"
            id="columnHead"
            checked={props.selectAllCheckboxStatus}
            onChange={handleCheckboxClick}
            aria-label={t('Select all')}
        />
    }
    else if(props.column.type === 'actions') {
        cell = <div className="columnHeader">
            <div className="caption">{props.column.caption}</div>
        </div>
    }
    else {
        const mdiIcon = props.sortingOrderAscending ? mdiChevronDown : mdiChevronUp;
        const ariaLabel = props.sortingOrderAscending ? t('Ascending (A-Z)') : t('Descending (Z-A)');
        cell = <button type="button" className="columnHeader" aria-label={props.column.caption + ' ' + (props.sortingColumn === props.column.id ? ariaLabel : t('Ascending (A-Z)'))}>
            <span className="caption">{props.column.caption}</span>
            <span className="icon">{props.sortingColumn === props.column.id ? <Icon path={mdiIcon} size={0.75} color="black" /> : <Icon path={mdiChevronDown} size={0.75} color="lightgrey" />}</span>
        </button>
    }

    return (
        <th scope="col" className={classes.join(' ')} style={htmlStyle} onClick={onClick} title={props.column.caption}>
            {cell}
        </th>
    );
}
  
export default ResultRowHeader;
