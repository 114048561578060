import React  from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalConfirm = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="lg"
            aria-labelledby="modalConfirmTitle"
            aria-describedby="modalConfirmText"
            centered
            show={props.showModalConfirm}
            onHide={() => {
                if(props.onHide && typeof(props.onHide) === 'function') {
                    props.onHide();
                }
                props.setShowModalConfirm(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title as="h1" className="h2" id="modalConfirmTitle">
                    {props.modalConfirmData?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="modalConfirmText">
                <p>{props.modalConfirmData?.text}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => props.modalConfirmCallback(props.modalConfirmData.type) }>
                    {props.modalConfirmData?.buttonCaption}
                </Button>
                <Button onClick={() => props.setShowModalConfirm(false)}>
                    {t('Cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirm;
