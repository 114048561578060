import React  from 'react';
import ResultColumn from './ResultColumn'

const ResultRow = (props) => {
    const renameInProgress = props.row?.status_rename === 'IN_PROGRESS';
    return (
        <tr className={renameInProgress && 'rename-in-progress'}>
            { props.columns.map((column, i) =>
                <ResultColumn
                    key={i}
                    row={props.row}
                    column={column}
                    rowCheckboxStatus={props.rowCheckboxStatus}
                    updateCheckboxStatus={props.updateCheckboxStatus}
                    setSelectAllCheckboxStatus={props.setSelectAllCheckboxStatus}
                    renameInProgress={renameInProgress}
                />
            ) }
        </tr>
    );
}
  
export default ResultRow;
