import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiHammerScrewdriver } from '@mdi/js';

import { UserAccountContext } from '../App';
import Layout from './Layout';
import PageHeader from './PageHeader';

const PrivateRoute = (props) => {
    const { t } = useTranslation();
    const userAccount = useContext(UserAccountContext);

    if(userAccount.is_staff !== true) {
        // User is not logged in
        return (
            <Layout>
                <PageHeader mdiIcon={mdiHammerScrewdriver}>{t('Admin Panel')}</PageHeader>
                <p>{t('You need to be logged in as an administrator to view this page.')}</p>
            </Layout>
        );
    }

    // User is logged in
    return props.children;
};

export default PrivateRoute;
