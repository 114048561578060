import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Icon from '@mdi/react'
import { mdiDownload, mdiLinkVariant, mdiLifebuoy } from '@mdi/js';

import { StateContext } from '../App';
import { GenericDetailScreen } from './Generic.js';
import CopyInput from './CopyInput.js';

const Help = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);

    const screenData = {
        title: t('Help'),
        subTitle: t('Instructions'),
        mdiIcon: mdiLifebuoy,
    };

    return (
        <GenericDetailScreen data={screenData}>
            <h3>{t('Introduction')}</h3>
            <p><Trans i18nKey="helpIntro">Welcome to the RVO Datahub where you can download offshore wind datasets.</Trans></p>

            <h3>{t('Downloading')}</h3>
            <p><Trans i18nKey="helpDownload1">You can download files directly from your browser in the <Link to={`/${locale}/explorer`}>Datahub Explorer</Link>. Find your file and click the download button (<Icon path={mdiDownload} size={0.75} title={t('Download file')} role="img" />). To protect against massive, automated access, download URLs are generated every time you click the download button. The URLs are valid for a week.</Trans></p>
            <p><Trans i18nKey="helpDownload2">Please note that some data set files are very large, which would result in longer download times. The file size of each file is indicated in the overviews and on the detail pages. On average, a high-speed glass fiber internet connection should be able to download a 1 GB file in about 10 minutes.</Trans></p>

            <h3>{t('AzCopy')}</h3>
            <p><Trans i18nKey="helpAzCopy1">To download large files, you may consider using <a href="https://learn.microsoft.com/en-us/azure/storage/common/storage-use-azcopy-v10" target="_blank" rel="noopener noreferrer">AzCopy</a>, a download manager by Microsoft to download files from Azure Storage to your local machine. AzCopy is available for Windows, MacOS and Linux. Please refer to the <a href="https://learn.microsoft.com/en-us/azure/storage/common/storage-use-azcopy-v10" target="_blank" rel="noopener noreferrer">AzCopy documentation</a> to learn about downloading and installing this download tool.</Trans></p>
            <p><Trans i18nKey="helpAzCopy2">After installation, it is recommended to also add the installation folder of AzCopy to your system path. The download commands provided by Datahub assume the AzCopy is added to your system path.</Trans></p>
            <p><Trans i18nKey="helpAzCopy3">On Windows, use the command below in the command prompt (replace the example path with your installation path to AzCopy):</Trans></p>
            <CopyInput label="Windows" className="mb-3">setx PATH "C:\path\to\azcopy;%PATH%"</CopyInput>
            <p><Trans i18nKey="helpAzCopy4">On MacOS and Linux, add the following line at the bottom of your ~/.zshrc or ~/.bashrc file (replace the example path with your installation path to AzCopy):</Trans></p>
            <CopyInput label="MacOS/Linux" className="mb-3">export PATH="/path/to/azcopy:$PATH"</CopyInput>
            <p><Trans i18nKey="helpAzCopy5">For your convenience, the Datahub provides both a download URL and an AzCopy download command for every file. Click the link button (<Icon path={mdiLinkVariant} size={0.75} title={t('Generate link')} role="img" />) to view them and easily copy them to your clipboard to be executed in the command prompt or terminal.</Trans></p>

            <h3>{t('Uncompressing')}</h3>
            <p><Trans i18nKey="helpUncompressing">Some files are compressed using ZIP. After downloading these files, you will need to uncompress the contents using an unzip uncompressing tool. Modern operating systems provide out-of-the-box solutions for this.</Trans></p>

            <h3>{t('Verifying downloads')}</h3>
            <p><Trans i18nKey="helpVerify1">After downloading, you may want to check the checksum of the files to ensure a correct transmission. This check is done automatically by AzCopy. If you download a file in your browser or different download tool, you may want to compare the checksum manually. Each modern operating system has its own tool to do this:</Trans></p>
            <div class="copyClipboardGroup mb-3">
                <CopyInput label="Windows">certutil -hashfile &lt;filename&gt; md5</CopyInput>
                <CopyInput label="MacOS">md5 &lt;filename&gt;</CopyInput>
                <CopyInput label="Linux">md5sum &lt;filename&gt;</CopyInput>
            </div>

            <p><Trans i18nKey="helpVerify2">Compare the given checksum with the one shown on the file detail page indicated by "MD5 hash". MD5 hashes may be formatted as hex and as Base64 values. In Datahub, both formats are provided. If the provided MD5 hash and the generated checksum are identical, the file was transferred correctly. Deviations should be the exception.</Trans></p>
        </GenericDetailScreen>
    )
}
  
export default Help;
