import { useTranslation } from 'react-i18next';

const FormattedDate = (props) => {
    const { i18n } = useTranslation();

    const locale = props.locale ?? i18n.language;
    const dateInput = props.children;
    const date = new Date(dateInput);
    const currentYear = new Date().getFullYear();
    let options = {};

    if(props.format === 'full') {
        options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    }
    else {  // short
        if(currentYear === date.getFullYear()) {
            options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        }
        else {
            options = { year: 'numeric', month: 'short', day: 'numeric'  };
        }
    }

    let result = date.toLocaleDateString(locale, options);

    if(typeof(props.capitalize) === 'undefined' || props.capitalize === true) {
        result = result.charAt(0).toUpperCase() + result.slice(1);
    }

    return result;
}

export default FormattedDate;
