import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { mdiRefresh } from '@mdi/js';

import IconButton from './IconButton';

const AlertRenameInProgress = (props) => {
    const { t } = useTranslation();

    function refreshPage() {
        window.location.reload();
    }

    return <Alert key="warning" variant="warning">
        <div class="d-flex">
            <div class="d-flex align-items-center me-3">
                <Spinner animation="border" />
            </div>
            <div>
                {t('The file is currently undergoing a renaming process. During this time, downloading or editing the file is temporarily disabled. Please be patient, as this process may take a few minutes.')}
            </div>
            <div class="d-flex align-items-center ms-3">
                <IconButton mdiIcon={mdiRefresh} onClick={refreshPage}>{t('Refresh')}</IconButton>
            </div>
        </div>
    </Alert>;
}

export default AlertRenameInProgress;
