import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getDropdownData } from '../data.js';
import { API_ENDPOINT } from '../const.js';

const ModalFilter = (props) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterRecords, setFilterRecords] = useState({});
    const mountedRef = useRef(false);

    const handleChangeFilter = (event, filterName) => {
        let columnData = {};
        props.columns.forEach(item => {
            if(item.id === filterName) {
                columnData = item;
            }
        });

        let filterValue;
        if(columnData.filterMultiple) {
            filterValue = props.filter[filterName];
            if(!Array.isArray(filterValue)) {
                filterValue = [];
            }

            const isChecked = event.target.checked;
            if(isChecked) {
                // When checking, just append the value to the array
                filterValue.push(event.target.value);;
            }
            else {
                // Recreate the array leaving out the current value
                let newArray = [];
                for(let i = 0; i < filterValue.length; i++) {
                    if(filterValue[i] !== event.target.value) {
                        newArray.push(filterValue[i]);
                    }
                }
                filterValue = newArray;
            }
        }
        else {
            filterValue = event.target.value;
        }

        if(filterValue.length === 0) {
            // Remove parameter from query string
            setSearchParams(searchParams => {
                searchParams.delete(filterName);
                return searchParams;
            });
        }
        else {
            // Set query string parameter
            if(Array.isArray(filterValue)) {
                // Remove all entries
                setSearchParams(searchParams => {
                    searchParams.delete(filterName);
                    return searchParams;
                });
                // Append them all
                for (let i = 0; i < filterValue.length; i++) {
                    setSearchParams(searchParams => {
                        // Add the new query param value to the queryString
                        searchParams.append(filterName, filterValue[i]);
                        return searchParams;
                    });
                }
            }
            else {
                setSearchParams(searchParams => {
                    // Add the new query param value to the queryString
                    searchParams.set(filterName, filterValue);
                    return searchParams;
                });
            }
        }

        // Update filter
        props.updateFilter(filterName, filterValue);
    }

    useEffect(() => {
        // Ensure the useEffect is executed only once
        // https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
        if(mountedRef.current) {
            return;
        }
        mountedRef.current = true;

        let endpoints = [];
        for(let j = 0; j < props.columns.length; j++) {
            if(typeof(props.columns[j].filter) === 'undefined' || props.columns[j].filter === true) {
                const key = props.columns[j].id;
                // Make sure the key exists in the API_ENDPOINT array (so don't try to retrieve "published")
                if(API_ENDPOINT[key]) {
                    endpoints.push(key);
                }
            }
        }
        if(endpoints.length > 0) {
            getDropdownData(endpoints, setFilterRecords, props.unpublished);
        }
    }, [props.columns, props.unpublished]);

    return (
        <Modal
            size="lg"
            aria-labelledby="modalFilterTitle"
            centered
            show={props.modalFilterShow}
            onHide={() => props.setModalFilterShow(false)}
        >
        <Modal.Header closeButton>
            <Modal.Title as="h1" id="modalFilterTitle" className="h2">
                {t('Filter')}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row>
                    { props.columns.map(
                        (item, i) =>
                            (typeof(item.filter) === 'undefined' || item.filter === true) &&
                                <Col className="col-12 col-sm-6 col-lg-6" key={i}>
                                    <Form.Group className="mb-3" controlId={'formFilter-' + item.id}>
                                        <h2 className="h6">{item.caption}</h2>
                                        {
                                            item.filterMultiple
                                                ?
                                                    Array.isArray(filterRecords[item.id]) &&
                                                        filterRecords[item.id].map((rec, k) =>
                                                            <Form.Check 
                                                                inline
                                                                key={k}
                                                                name={item.id}
                                                                value={rec.id}
                                                                type="checkbox"
                                                                id={item.id + '-' + rec.id}
                                                                label={rec.name}
                                                                onChange={(event) => { handleChangeFilter(event, item.id) }}
                                                                defaultChecked={props.filter[item.id] ? props.filter[item.id].includes(''+rec.id) : false}
                                                            />
                                                    )
                                                :
                                                    (
                                                        item.type === 'boolean'
                                                        ?
                                                            <div>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name={item.id}
                                                                    inline
                                                                    label={'(' + t('All') + ')'}
                                                                    value=""
                                                                    id={item.id + '-all'}
                                                                    defaultChecked={(props.filter[item.id] !== true && props.filter[item.id] !== false) ?? ''}
                                                                    onChange={(event) => { handleChangeFilter(event, item.id) }}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name={item.id}
                                                                    inline
                                                                    label={t('Published')}
                                                                    value="true"
                                                                    id={item.id + '-true'}
                                                                    defaultChecked={props.filter[item.id] === true ?? ''}
                                                                    onChange={(event) => { handleChangeFilter(event, item.id) }}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name={item.id}
                                                                    inline
                                                                    label={t('Not published')}
                                                                    value="false"
                                                                    id={item.id + '-false'}
                                                                    defaultChecked={props.filter[item.id] === false ?? ''}
                                                                    onChange={(event) => { handleChangeFilter(event, item.id) }}
                                                                />
                                                            </div>
                                                        :
                                                            <Form.Select
                                                                aria-label={item.caption}
                                                                size="sm"
                                                                onChange={(event) => { handleChangeFilter(event, item.id) }}
                                                                value={props.filter[item.id] ?? ''}
                                                            >
                                                                <option value="" key="-1">{'(' + t('All') + ')'}</option>
                                                                {
                                                                    Array.isArray(filterRecords[item.id]) &&
                                                                        filterRecords[item.id].map((rec, k) =>
                                                                            <option value={rec.id} key={k}>{rec.name}</option>
                                                                        )
                                                                }
                                                            </Form.Select>
                                                    )
                                        }
                                    </Form.Group>
                                </Col>
                        )
                    }
                </Row>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => props.setModalFilterShow(false)}>
                {t('Close')}
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default ModalFilter;
