import React  from 'react';
import { mdiApplication, mdiDownload, mdiLinkVariant, mdiEye, mdiPencil, mdiDelete, mdiDotsVertical } from '@mdi/js';
import IconButton from '../IconButton';

export const ResultColumnActionsItem = (props) => {
    let mdiIcon = null;
    if(!props.action.mdiIcon) {
        if(props.action.type === 'download') {
            mdiIcon = mdiDownload;
        }
        else if(props.action.type === 'generate_link') {
            mdiIcon = mdiLinkVariant;
        }
        else if(props.action.type === 'view') {
            mdiIcon = mdiEye;
        }
        else if(props.action.type === 'edit') {
            mdiIcon = mdiPencil;
        }
        else if(props.action.type === 'delete') {
            mdiIcon = mdiDelete;
        }
        else if(props.action.type === 'menu') {
            mdiIcon = mdiDotsVertical;
        }
        else {
            mdiIcon = mdiApplication;
        }
    }

    let className = 'rowAction';
    if(props.action.type === 'delete') {
        className += ' delete';
    }

    if(props.action.onClick) {
        return(
            <div
                key={props.iterator}
                className={className}
                title={props.action.title}
                data-rec-name={props.rowName}
                data-rec-id={props.rowId}
            >
                <IconButton aria-label={props.action.title} onClick={props.action.onClick} mdiIcon={mdiIcon} disabled={props.action.type !== 'view' && props.renameInProgress}></IconButton>
            </div>
        );
    }
    else {
        let route = props.action.route ?? '/';
        route = route.replaceAll(':id', '' + props.rowId);
        return(
            <div
                key={props.iterator}
                className={className}
                title={props.action.title}
            >    
                <IconButton aria-label={props.action.title} route={route} mdiIcon={mdiIcon} disabled={props.action.type !== 'view' && props.renameInProgress}></IconButton>
            </div>
        );
    }
}
  
export default ResultColumnActionsItem;
