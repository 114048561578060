import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiContentSave, mdiCancel, mdiPencil, mdiFile, mdiDownload, mdiLinkVariant } from '@mdi/js';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { API_ENDPOINT_FILES } from '../../const.js';
import { retrieveData, saveData, deleteData, getDropdownData, downloadFile } from '../../data.js';
import { StateContext } from '../../App';
import { GenericDetailScreen, GenericDetailError } from '../Generic.js';
import Layout from '../Layout';
import Result from '../Result/Result';
import PageHeader from '../PageHeader';
import IconButtonSet from '../IconButtonSet';
import IconButton from '../IconButton';
import LoadingSpinner from '../LoadingSpinner.js';
import Published from '../Published';
import FormattedDate from '../FormattedDate';
import FormattedMD5Hash from '../FormattedMD5Hash';
import ModalDownload from '../ModalDownload.js'
import FileSize from '../FileSize';
import FormattedNumber from '../FormattedNumber';
import CellValue from '../CellValue';
import AlertRenameInProgress from '../AlertRenameInProgress';

export const Files = () => {
    const { t } = useTranslation();
    const { locale, setAlert, setModalText, setShowModal, setShowModalConfirm, setModalConfirmData } = useContext(StateContext);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [refreshData, setRefreshData] = useState(false);
    const [checkedIds, setCheckedIds] = useState(false);
    const [resetCheckboxes, setResetCheckboxes] = useState(false);
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [fileData, setFileData] = useState({});
    const [fileDownloadData, setFileDownloadData] = useState({});

    const columns = [
        { id: 'checkbox', type: 'checkbox', sort: false, filter: false },
        { id: 'name', type: 'filename', route: `/${locale}/admin/files/:id/view`, caption: t('Filename'), width: '15%', class: 'width-50p-sm', filter: false },
        { id: 'published', type: 'boolean', width: '7em', caption: t('Published') },
        { id: 'zone', caption: t('Zone'), key: 'name', class: 'width-50p-sm', filterMultiple: true },
        { id: 'chapter', key: 'name', caption: t('Chapter'), class: 'hide-lg' },
        { id: 'study', key: 'name', caption: t('Study'), class: 'hide-lg' },
        { id: 'sensor', key: 'name', caption: t('Sensor'), width: '8em', class: 'hide-xl' },
        { id: 'classification', key: 'name', caption: t('Classification'), width: '8em', class: 'hide-xl', filterMultiple: true },
        { id: 'extension', key: 'name', caption: t('Extension'), width: '6em', class: 'hide-xl' },
        { id: 'size', type: 'filesize', caption: t('Size'), align: 'right', width: '6em', class: 'hide-sm', filter: false },
        { id: 'date_modified', type: 'datetime', caption: t('Updated'), width: '8em', class: 'hide-sm', filter: false },
        { id: 'actions', type: 'actions', caption: t('Actions'),
            actions: [
                {type: 'view', title: t('View file'), route: `/${locale}/admin/files/:id/view`},
                {type: 'edit', title: t('Edit file'), route: `/${locale}/admin/files/:id/edit`},
                {type: 'delete', title: t('Delete file'), onClick: (event) => { deleteFile(event) }}
            ],
            sort: false,
            filter: false
        },
    ];

    const controlItems = [
        'search',
        'filter',
        'sort',
    ];

    const bulkActions = [
        { id: 'download', caption: t('Show download info'), onClick: () => { downloadInfoMultipleFiles() } },
        { id: 'unpublish', caption: t('Unpublish'), onClick: () => unpublishMultipleFiles() },
        { id: 'delete', caption: t('Delete'), onClick: () => deleteMultipleFiles() },
    ];

    const setResultCheckboxes = (checkboxStatus) => {
        let arrayId = [];
        for (const [key, value] of Object.entries(checkboxStatus)) {
            if(value === true) {
                arrayId.push(key);
            }
        }
        setCheckedIds(arrayId);
    }

    const modalConfirmCallback = (type) => {
        if(type === 'delete') {
            deleteConfirmed();
        }
        else if(type === 'unpublish') {
            unpublishConfirmed();
        }
    }

    const downloadInfoMultipleFiles = () => {
        if(checkedIds.length > 0) {
            retrieveData(API_ENDPOINT_FILES + 'download', { ids: checkedIds.join(',') }, (data) => {
                if(data.success === true) {
                    setFileData(null);
                    setFileDownloadData(data.result);
                    setShowModalDownload(true);

                    // Reset checkboxes in child component Result
                    setResetCheckboxes(true);
                }
                else {
                    console.error('Error retrieving multiple file info');
                }
            });
        }
    }

    const unpublishMultipleFiles = () => {
        if(checkedIds.length > 0) {
            unpublishShowConfirmation({id: checkedIds});
        }
    }

    const unpublishShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);

        let title = null;
        let text = null;
        if(!Array.isArray(rowData.id)) {
            return false;
        }

        title = t('Unpublish files');
        if(rowData.id.length === 1) {
            text = t('Are you sure you want to unpublish 1 file?');
        }
        else {
            text = t('Are you sure you want to unpublish {{num}} files?', { num: rowData.id.length });
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Unpublish'), type: 'unpublish' });
        setShowModalConfirm(true);
    }

    const unpublishConfirmed = () => {
        const arrayFileIds = selectedRowData.id;      // Contains an array of file ids
        if(!Array.isArray(arrayFileIds) || arrayFileIds.length === 0) {
            return;
        }

        saveData(API_ENDPOINT_FILES, null, { ids: arrayFileIds }, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                const numberOfUnpublishedFiles = data?.data['204'] || 0;
                if(numberOfUnpublishedFiles === 1) {
                    setAlert({text: t('Operation completed: 1 file unpublished.')});
                }
                else {
                    setAlert({text: t('Operation completed: {{num}} file(s) unpublished.', { num: numberOfUnpublishedFiles })});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                setModalText(t('Could not unpublish the selected files. The system returned the following error message: {{error}}', { error: data.error }));
                setShowModal(true);
            }
        }, 'PUT');
    }

    const deleteMultipleFiles = () => {
        if(checkedIds.length > 0) {
            deleteShowConfirmation({id: checkedIds});
        }
    }

    const deleteFile = (event) => {
        const fileId = event.currentTarget.parentNode.getAttribute('data-rec-id');
        const fileName = event.currentTarget.parentNode.getAttribute('data-rec-name');
        if(fileId <= 0) {
            return;
        }

        const rowData = { id: fileId, name: fileName };
        deleteShowConfirmation(rowData);
    }

    const deleteShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);
        let title = null;
        let text = null;
        if(Array.isArray(rowData.id)) {
            title = t('Delete files');
            if(rowData.id.length === 1) {
                text = t('Are you sure you want to delete 1 file?');
            }
            else {
                text = t('Are you sure you want to delete {{num}} files?', { num: rowData.id.length });
            }
        }
        else {
            title = t('Delete file');
            text = t('Are you sure you want to delete file "{{name}}" (#{{id}})?', rowData);
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Delete'), type: 'delete' });
        setShowModalConfirm(true);
    }

    const deleteConfirmed = () => {
        const fileId = selectedRowData.id;      // Contains a single integer of a file id or an array of ids
        const fileName = selectedRowData.name;  // Contains the filename in case fileId is an integer
        if(Array.isArray(fileId) && fileId.length === 0) {
            return;
        }
        else if(fileId <= 0) {
            return;
        }

        deleteData(API_ENDPOINT_FILES, fileId, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                if(Array.isArray(selectedRowData.id)) {
                    // Multiple items
                    const numberOfDeletedFiles = data?.data['204'] || 0;
                    if(numberOfDeletedFiles === 1) {
                        setAlert({text: t('Operation completed: 1 file successfully deleted.')});
                    }
                    else {
                        setAlert({text: t('Operation completed: {{num}} file(s) successfully deleted.', { num: numberOfDeletedFiles })});
                    }
                }
                else {
                    // Single item
                    setAlert({text: t('Operation completed: File "{{name}}" (#{{id}}) successfully deleted.', selectedRowData)});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                if(Array.isArray(selectedRowData.id)) {
                    setModalText(t('Could not delete the selected file(s). The system returned the following error message: {{error}}', { error: data.error }));
                }
                else {
                    setModalText(t('Could not delete file {{name}} (#{{id}}). The system returned the following error message: {{error}}', {id: fileId, name: fileName, error: data.error}));
                }
                setShowModal(true);
            }
        });
    }
    
    const handleCloseModalDownload = () => setShowModalDownload(false);

    return (
        <Layout modalConfirmCallback={modalConfirmCallback}>
            <ModalDownload
                show={showModalDownload}
                onClose={handleCloseModalDownload}
                fileData={fileData}
                fileDownloadData={fileDownloadData}
            />
            <PageHeader mdiIcon={mdiFile} path={[{caption: t('Admin Panel'), route: `/${locale}/admin`}]}>{t('Files')}</PageHeader>
            <Result
                endpoint={API_ENDPOINT_FILES}
                unpublished={true}
                columns={columns}
                controlItems={controlItems}
                bulkActions={bulkActions}
                setResultCheckboxes={setResultCheckboxes}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                resetCheckboxes={resetCheckboxes}
                setResetCheckboxes={setResetCheckboxes}
            />
        </Layout>
    );
}

const getFile = (fileId, callback) => {
    fileId = parseInt(fileId);
    if(fileId > 0) {
        const url = API_ENDPOINT_FILES + fileId + '/';
        retrieveData(url, { 'also_unpublished': 'true' }, (data) => {
            if(typeof(callback) === 'function') {
                callback(data);
            }
        });
    }
    else {
        // Don't bother trying to retrieve a record for an ID <= 0, immediately return 404
        if(typeof(callback) === 'function') {
            callback({result: null, success: false, status: 404});
        }
    }
}

export const FilesEdit = (props) => {
    const { fileId } = useParams();
    const action = props.action;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale, setModalText, setShowModal } = useContext(StateContext);

    const [formIsDisabled, setFormIsDisabled] = useState(fileId > 0 ? true : false);
    const [formIsSaving, setFormIsSaving] = useState(false);
    const [data, setData] = React.useState(null);
    const [dropdownData, setDropdownData] = React.useState({});

    const [showTooltipFileName, setShowTooltipFileName] = useState(false);
    const [showTooltipZone, setShowTooltipZone] = useState(false);
    const [showTooltipChapter, setShowTooltipChapter] = useState(false);
    const [showTooltipStudy, setShowTooltipStudy] = useState(false);
    const [showTooltipSensor, setShowTooltipSensor] = useState(false);
    const [showTooltipClassification, setShowTooltipClassification] = useState(false);

    const [fileName, setFileName] = React.useState('');
    const [fileZone, setFileZone] = React.useState('');
    const [fileChapter, setFileChapter] = React.useState('');
    const [fileStudy, setFileStudy] = React.useState('');
    const [fileClassification, setFileClassification] = React.useState('');
    const [fileSensor, setFileSensor] = React.useState('');
    const [filePublished, setFilePublished] = React.useState(false);

    const targetName = useRef(null);
    const targetZone = useRef(null);
    const targetChapter = useRef(null);
    const targetStudy = useRef(null);
    const targetClassification = useRef(null);
    const targetSensor = useRef(null);
    const targetNameLabel = useRef(null);
    const targetZoneLabel = useRef(null);
    const targetChapterLabel = useRef(null);
    const targetStudyLabel = useRef(null);
    const targetClassificationLabel = useRef(null);
    const targetSensorLabel = useRef(null);

    const screenData = {
        title: action === 'edit' && fileId > 0 ? t('Edit file') : t('New file'),
        subTitle: fileId > 0 ? t('File (#{{id}})', { id: fileId}) : t('File'),
        mdiIcon: mdiFile,
        path: [
            {caption: t('Files'), route: `/${locale}/admin/files`},
        ],
    }

    const submitForm = (event, fileId) => {
        event.preventDefault();

        if (!fileName) {
            // Filename is required
            setShowTooltipFileName(true);
            targetName.current.focus();
            return false;
        }

        if (!fileZone) {
            // Zone is required
            setShowTooltipZone(true);
            targetZone.current.focus();
            return false;
        }

        if (!fileChapter) {
            // Chapter is required
            setShowTooltipChapter(true);
            targetChapter.current.focus();
            return false;
        }

        if (!fileStudy) {
            // Study is required
            setShowTooltipStudy(true);
            targetStudy.current.focus();
            return false;
        }

        if (!fileClassification) {
            // Classification is required            
            setShowTooltipClassification(true);
            targetClassification.current.focus();
            return false;
        }
    
        // Disable form and show spinner
        setFormIsDisabled(true);
        setFormIsSaving(true);
    
        // Setup payload
        const payload = {
            name: fileName,
            zone: parseInt(fileZone),
            chapter: parseInt(fileChapter),
            study: parseInt(fileStudy),
            classification: parseInt(fileClassification),
            sensor: parseInt(fileSensor),
            published: !!filePublished,
        };

        // Send data
        saveData(API_ENDPOINT_FILES, fileId, payload, (response) => {
            // Enable form and hide spinner
            setFormIsDisabled(false);
            setFormIsSaving(false);

            if(response.success === true) {
                // Success
                if(!fileId) {
                    fileId = response.data.id;
                }

                // Navigate to detail view
                navigate(`/${locale}/admin/files/${fileId}/view`);
            }
            else {
                // Failure
                setModalText(response?.error?.message ?? 'Error');
                setShowModal(true);
            }    
        });
    }

    const handleChangeFileName = (event) => {
        setFileName(event.target.value);
        setShowTooltipFileName(false);
    }

    const handleChangeFileZone = (event) => {
        setFileZone(event.target.value);
        setShowTooltipZone(false);
    }

    const handleChangeFileChapter = (event) => {
        setFileChapter(event.target.value);
        setShowTooltipChapter(false);
    }

    const handleChangeFileStudy = (event) => {
        setFileStudy(event.target.value);
        setShowTooltipStudy(false);
    }

    const handleChangeFileClassification = (event) => {
        setFileClassification(event.target.value);
        setShowTooltipClassification(false);
    }

    const handleChangeFileSensor = (event) => {
        setFileSensor(event.target.value);
        setShowTooltipSensor(false);
    }

    const handleChangeFilePublished = (event) => {
        setFilePublished(!!event.target.checked);
    }

    const getData = (fileId) => {
        getFile(fileId, (data) => {
            setData(data);
            if(data.success === true) {
                // Set form values
                setFileName(data.result?.name);
                setFileZone(data.result?.zone?.id);
                setFileChapter(data.result?.chapter?.id);
                setFileStudy(data.result?.study?.id);
                setFileClassification(data.result?.classification?.id);
                setFileSensor(data.result?.sensor?.id);
                setFilePublished(!!data.result?.published);
            }

            // Enable form
            setFormIsDisabled(false);
        });        
    }
    
    useEffect(() => {
        if(action === 'edit') {
            getData(fileId);
        }
        else {
            setData({ success: true });
        }
    }, [action, fileId]);    

    useEffect(() => {
        getDropdownData(['zone', 'chapter', 'study', 'classification', 'sensor'], setDropdownData, true);
    }, []);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }
    
    const renameInProgress = data.result?.status_rename === 'IN_PROGRESS';

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>

                {renameInProgress &&  <AlertRenameInProgress />}

                <Form onSubmit={event => submitForm(event, fileId)} name="FileForm">
                    <fieldset disabled={formIsDisabled}>
                        <FloatingLabel controlId="fileName" ref={targetNameLabel} label={t('Name')} className="mb-4">
                            <Form.Control ref={targetName} type="text" value={fileName} placeholder={t('Filename')} onChange={handleChangeFileName} onBlur={() => { setShowTooltipFileName(false) }} disabled={renameInProgress} autoFocus />
                        </FloatingLabel>
                        <Overlay target={targetNameLabel.current} show={showTooltipFileName} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please enter a filename.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileZone" ref={targetZoneLabel} label={t('Zone')} className="mb-4">
                            <Form.Select ref={targetZone} onChange={handleChangeFileZone} onBlur={() => { setShowTooltipZone(false) }} value={fileZone} disabled={renameInProgress}>
                                <option value="">{t('(None)')}</option>
                                {
                                    dropdownData?.zone &&
                                        dropdownData.zone.map((rec, k) =>
                                            <option value={rec.id} key={k}>{rec.name}</option>
                                        )
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetZoneLabel.current} show={showTooltipZone} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a zone.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileChapter" ref={targetChapterLabel} label={t('Chapter')} className="mb-4">
                            <Form.Select ref={targetChapter} onChange={handleChangeFileChapter} onBlur={() => { setShowTooltipChapter(false) }} value={fileChapter} disabled={renameInProgress}>
                                <option value="">{t('(None)')}</option>
                                {
                                    dropdownData?.chapter &&
                                        dropdownData.chapter.map((rec, k) =>
                                            <option value={rec.id} key={k}>{rec.name}</option>
                                        )
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetChapterLabel.current} show={showTooltipChapter} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a chapter.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileStudy" ref={targetStudyLabel} label={t('Study')} className="mb-4">
                            <Form.Select ref={targetStudy} onChange={handleChangeFileStudy} onBlur={() => { setShowTooltipStudy(false) }} value={fileStudy} disabled={renameInProgress}>
                                {
                                    dropdownData?.study &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.study.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetStudyLabel.current} show={showTooltipStudy} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a study.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileClassification" ref={targetClassificationLabel} label={t('Classification')} className="mb-4">
                            <Form.Select ref={targetClassification} onChange={handleChangeFileClassification} onBlur={() => { setShowTooltipClassification(false) }} value={fileClassification} disabled={renameInProgress}>
                                {
                                    dropdownData?.classification &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.classification.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetClassificationLabel.current} show={showTooltipClassification} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a classification.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileSensor" ref={targetSensorLabel} label={t('Sensor')} className="mb-4">
                            <Form.Select ref={targetSensor} onChange={handleChangeFileSensor} onBlur={() => { setShowTooltipChapter(false) }} value={fileSensor} disabled={renameInProgress}>
                                {
                                    dropdownData?.sensor &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.sensor.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetSensorLabel.current} show={showTooltipSensor} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a sensor.')}
                            </Tooltip>
                        </Overlay>

                        <div className="mb-4">
                            <Form.Label>{t('Publication status')}</Form.Label>
                            <Form.Switch 
                                defaultChecked={filePublished}
                                onChange={handleChangeFilePublished}
                                id="filePublished"
                                label={t('File is published to website')}
                                disabled={renameInProgress}
                            />
                        </div>

                        <hr />

                        <IconButtonSet showSpinner={formIsSaving}>
                            <IconButton mdiIcon={mdiContentSave} onClick={(event) => submitForm(event, fileId)} disabled={renameInProgress}>{t('Save')}</IconButton>
                            <IconButton mdiIcon={mdiCancel} variant="primary" route={`/${locale}/admin/files`}>{t('Cancel')}</IconButton>
                        </IconButtonSet>
                    </fieldset>
                </Form>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <FilesDetailError data={data} />
        </GenericDetailScreen>
    )
}

export const FilesView = () => {
    const { fileId } = useParams();
    const { t } = useTranslation();
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [fileData, setFileData] = useState({});
    const [fileDownloadData, setFileDownloadData] = useState({});
    const [data, setData] = React.useState(null);
    const { locale } = useContext(StateContext);

    const handleShowModalDownload = () => {
        if(fileId <= 0) {
            return;
        }

        getFile(fileId, (data) => {
            setFileData(data.result);
            if(data.success === true) {
                const endpoint = API_ENDPOINT_FILES + fileId + '/download/';
                retrieveData(endpoint, {}, (data) => {
                    if(data.success === true) {
                        setFileDownloadData(data.result);
                        setShowModalDownload(true);
                    }
                    else {
                        console.error('Error retrieving file info');
                    }
                });
            }
        });

    }

    const handleCloseModalDownload = () => setShowModalDownload(false);

    const screenData = {
        title: t('View file'),
        subTitle: t('File') + (fileId > 0 ? ' (#' + fileId + ')' : ''),
        mdiIcon: mdiFile,
        path: [
            { caption: t('Files'), route: `/${locale}/admin/files` },
        ],
        backButton: true,
    }
    
    useEffect(() => {
        getFile(fileId, data => setData(data));
    }, [fileId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    const renameInProgress = data.result?.status_rename === 'IN_PROGRESS';

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>
                <ModalDownload
                    show={showModalDownload}     
                    onClose={handleCloseModalDownload} 
                    fileData={fileData}
                    fileDownloadData={fileDownloadData}
                />

                {renameInProgress &&  <AlertRenameInProgress />}

                <Table bordered striped responsive className="detailTable mb-4">
                    <tbody>
                        <tr>
                            <th>{t('ID')}</th>
                            <td>#{data.result?.id}</td>
                        </tr>
                        <tr>
                            <th>{t('Filename')}</th>
                            <td>{data.result?.name}{renameInProgress && <Spinner animation="border" size="sm" className="ms-2" />}</td>
                        </tr>
                        <tr>
                            <th>{t('Zone')}</th>
                            <td><CellValue value={data.result?.zone} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Chapter')}</th>
                            <td><CellValue value={data.result?.chapter} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Study')}</th>
                            <td><CellValue value={data.result?.study} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Sensor')}</th>
                            <td><CellValue value={data.result?.sensor} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Classification')}</th>
                            <td><CellValue value={data.result?.classification} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Extension')}</th>
                            <td><CellValue value={data.result?.extension} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('File size')}</th>
                            <td><FileSize>{data.result?.size}</FileSize></td>
                        </tr>
                        <tr>
                            <th>{t('MD5 hash')}</th>
                            <td><FormattedMD5Hash>{data.result?.md5_hash}</FormattedMD5Hash></td>
                        </tr>
                        <tr>
                            <th>{t('Number of downloads')}</th>
                            <td><FormattedNumber>{data.result?.nr_downloads}</FormattedNumber></td>
                        </tr>
                        <tr>
                            <th>{t('Published')}</th>
                            <td><Published value={data.result?.published} /></td>
                        </tr>
                        <tr>
                            <th>{t('Created')}</th>
                            <td><FormattedDate format="full">{data.result?.date_created}</FormattedDate></td>
                        </tr>
                        <tr>
                            <th>{t('Updated')}</th>
                            <td><FormattedDate format="full">{data.result?.date_modified}</FormattedDate></td>
                        </tr>
                    </tbody>
                </Table>

                <IconButtonSet>
                    <IconButton mdiIcon={mdiDownload} onClick={() => {downloadFile(fileId)}} disabled={renameInProgress}>{t('Download file')}</IconButton>  
                    <IconButton mdiIcon={mdiLinkVariant} onClick={() => {handleShowModalDownload()}} disabled={renameInProgress}>{t('Show download info')}</IconButton>   
                    <IconButton mdiIcon={mdiPencil} route={`/${locale}/admin/files/${fileId}/edit`} disabled={renameInProgress}>{t('Edit file')}</IconButton>
                </IconButtonSet>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <FilesDetailError data={data} />
        </GenericDetailScreen>
    )
}

const FilesDetailError = (props) => {
    const { t } = useTranslation();

    return (
        <GenericDetailError
            data={props.data}
            messageNotFound={t('This file does not exist (anymore).')}
        />
    )
}
