import React from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';

import CopyInput from './CopyInput.js';
import FileSize from './FileSize';

const ModalDownload = (props) => {
    const { t } = useTranslation();

    const isBulk = Array.isArray(props.fileDownloadData);
    let arrayDownloadUrls = [];
    let arrayDownloadCommands = [];
    if(isBulk === true) {
        // Multiple files
        props.fileDownloadData.forEach(function (rec, index) {
            arrayDownloadUrls.push(rec.download_command);
            arrayDownloadCommands.push(rec.download_url);
        });
    }
 
    return (
        <Modal
            size="lg"
            aria-labelledby="ModalDownloadTitle"
            show={props.show}
            onHide={props.onClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title as="h1" id="ModalDownloadTitle" className="h2">
                    {t('Download info')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props?.fileData?.name &&
                    <Table bordered striped responsive className="detailTable mb-4">
                        <tbody>
                            <tr>
                                <th>{t('Filename')}</th>
                                <td>{props?.fileData?.name}</td>
                            </tr>
                            <tr>
                                <th>{t('MD5 hash')}</th>
                                <td>{props?.fileData?.md5_hash}</td>
                            </tr>
                            <tr>
                                <th>{t('Size')}</th>
                                <td><FileSize>{props?.fileData?.size}</FileSize></td>
                            </tr>
                        </tbody>
                    </Table>
                }

                <Form>
                    <div>
                        <CopyInput caption={isBulk ? t('Download URLs ({{num}})', { num: arrayDownloadUrls.length}) : t('Download URL')}>
                            {isBulk ? arrayDownloadUrls : props?.fileDownloadData?.download_url}
                        </CopyInput>
                    </div>
                    <div class="mt-4">
                        <CopyInput caption={isBulk ? t('Download commands ({{num}})', { num: arrayDownloadCommands.length}) : t('Download command')}>
                            {isBulk ? arrayDownloadCommands : props?.fileDownloadData?.download_command}
                        </CopyInput>
                    </div>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onClose}>{t('Close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDownload;
