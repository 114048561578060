import React  from 'react';
import ResultCell from './ResultCell';

const ResultColumn = (props) => {
    const onHover = (event) => {
        if(!event.target.title) {
            if(event.target.childNodes.length === 1 && event.target.firstChild.nodeType === Node.TEXT_NODE) {
                event.target.title = event.target.innerHTML;
            }
        }
    }
    
    let classes = [];
    classes.push('column-' + props.column.id);
    if(props.column.align === 'right') {
        classes.push('text-align-right');
    }
    if(props.column.class) {
        classes.push(props.column.class);
    }

    const rowId = props.row.id;
    const rowName = props.row?.name ?? '';
    const value = props.row[props.column.id];

    return (
        <td className={classes.join(' ')} onMouseOver={onHover}>
            <ResultCell
                column={props.column}
                rowId={rowId}
                rowName={rowName}
                value={value}
                rowCheckboxStatus={props.rowCheckboxStatus}
                updateCheckboxStatus={props.updateCheckboxStatus}
                setSelectAllCheckboxStatus={props.setSelectAllCheckboxStatus}
                renameInProgress={props.renameInProgress}
            />
        </td>

    );
}
  
export default ResultColumn;
