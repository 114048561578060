import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiPencil, mdiLinkVariant, mdiDownload, mdiFileDownload } from '@mdi/js';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { GenericDetailScreen } from './Generic.js';
import { StateContext, UserAccountContext } from '../App';
import { API_ENDPOINT_FILES } from '../const.js';
import { retrieveData, downloadFile } from '../data.js';
import Layout from './Layout';
import Result from './Result/Result';
import PageHeader from './PageHeader';
import IconButtonSet from './IconButtonSet';
import IconButton from './IconButton';
import LoadingSpinner from './LoadingSpinner.js';
import Published from './Published';
import FormattedDate from './FormattedDate';
import ModalDownload from './ModalDownload.js';
import FormattedMD5Hash from './FormattedMD5Hash';
import CellValue from './CellValue';
import AlertRenameInProgress from './AlertRenameInProgress';

export const Explorer = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);
    const [checkedIds, setCheckedIds] = useState(false);
    const [resetCheckboxes, setResetCheckboxes] = useState(false);
    const [showModalDownload, setShowModalDownload] = useState(false); 
    const [fileData, setFileData] = useState({});
    const [fileDownloadData, setFileDownloadData] = useState({});

    const handleShowModalDownload = (event) => {
        setShowModalDownload(true);
        const fileId = event.currentTarget.parentNode.getAttribute('data-rec-id');
        if(fileId <= 0) {
            return;
        }

        getFile(fileId, (data) => {
            setFileData(data.result);
            if(data.success === true) {
                const endpoint = API_ENDPOINT_FILES + fileId + '/download/';
                retrieveData(endpoint, {}, (data) => {
                    if(data.success === true) {
                        setFileDownloadData(data.result);
                        setShowModalDownload(true);
                    }
                    else {
                        console.error('Error retrieving file info');
                    }
                });
            }
        });
    }

    const handleCloseModalDownload = () => setShowModalDownload(false);

    const columns = [
        { id: 'checkbox', type: 'checkbox', sort: false, filter: false },
        { id: 'name', type: 'filename', route: `/${locale}/explorer/:id`, caption: t('Filename'), width: '15%', class: 'width-50p-sm', filter: false },
        { id: 'zone', caption: t('Zone'), key: 'name', class: 'width-50p-sm', filterMultiple: true },
        { id: 'chapter', key: 'name', caption: t('Chapter'), class: 'hide-lg' },
        { id: 'study', key: 'name', caption: t('Study'), class: 'hide-lg' },
        { id: 'sensor', key: 'name', caption: t('Sensor'), width: '8em', class: 'hide-xl' },
        { id: 'classification', key: 'name', caption: t('Classification'), width: '8em', class: 'hide-xl', filterMultiple: true },
        { id: 'extension', key: 'name', caption: t('Extension'), width: '5em', class: 'hide-xl' },
        { id: 'size', type: 'filesize', caption: t('Size'), align: 'right', width: '6em', class: 'hide-sm', filter: false },
        { id: 'date_modified', type: 'datetime', caption: t('Updated'), width: '8em', class: 'hide-sm', filter: false },
        { id: 'actions', type: 'actions', caption: t('Actions'),
            actions: [
                {type: 'view', title: t('View file'), route: `/${locale}/explorer/:id`},
                {type: 'download', title: t('Download file'), onClick: (event) => downloadFile(event.currentTarget.parentNode.getAttribute('data-rec-id')) },
                {type: 'generate_link', title: t('Show download info'), onClick: (event) =>  handleShowModalDownload(event) }
            ],
            sort: false,
            filter: false
        },
    ];

    const controlItems = [
        'search',
        'filter',
        'sort'
    ];

    const bulkActions = [
        { id: 'download', caption: t('Show download info'), onClick: () => { downloadInfoMultipleFiles() } },
    ];

    const setResultCheckboxes = (checkboxStatus) => {
        let arrayId = [];
        for (const [key, value] of Object.entries(checkboxStatus)) {
            if(value === true) {
                arrayId.push(key);
            }
        }
        setCheckedIds(arrayId);
    }

    const downloadInfoMultipleFiles = () => {
        if(checkedIds.length > 0) {
            retrieveData(API_ENDPOINT_FILES + 'download', { ids: checkedIds.join(',') }, (data) => {
                if(data.success === true) {
                    setFileData(null);
                    setFileDownloadData(data.result);
                    setShowModalDownload(true);

                    // Reset checkboxes in child component Result
                    setResetCheckboxes(true);
                }
                else {
                    console.error('Error retrieving multiple file info');
                }
            });
        }
    }

    return (
        <Layout>
            <ModalDownload
                show={showModalDownload}     
                onClose={handleCloseModalDownload} 
                fileData={fileData}
                fileDownloadData={fileDownloadData}
            />
            <PageHeader mdiIcon={mdiFileDownload}>{t('Datahub Explorer')}</PageHeader>
            <Result
                endpoint={API_ENDPOINT_FILES}
                columns={columns}
                controlItems={controlItems}
                bulkActions={bulkActions}
                setResultCheckboxes={setResultCheckboxes}
                resetCheckboxes={resetCheckboxes}
                setResetCheckboxes={setResetCheckboxes}
            />
        </Layout>
    );
}

const getFile = (fileId, callback) => {
    fileId = parseInt(fileId);
    if(fileId > 0) {
        const url = API_ENDPOINT_FILES + fileId + '/';
        retrieveData(url, { 'also_unpublished': 'true' }, (data) => {
            if(typeof(callback) === 'function') {
                callback(data);
            }
        });
    }
    else {
        // Don't bother trying to retrieve a record for an ID <= 0, immediately return 404
        if(typeof(callback) === 'function') {
            callback({result: null, success: false, status: 404});
        }
    }
}

export const ExplorerDetail = () => {
    const { fileId } = useParams();
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);
    const userAccount = useContext(UserAccountContext);
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [fileData, setFileData] = useState({});
    const [fileDownloadData, setFileDownloadData] = useState({});
    const [data, setData] = React.useState(null);

    const handleShowModalDownload = () => {
        setShowModalDownload(true);
        if(fileId <= 0) {
            return;
        }

        getFile(fileId, (data) => {
            setFileData(data.result);
            if(data.success === true) {
                const endpoint = API_ENDPOINT_FILES + fileId + '/download/';
                retrieveData(endpoint, {}, (data) => {
                    if(data.success === true) {
                        setFileDownloadData(data.result);
                        setShowModalDownload(true);
                    }
                    else {
                        console.error('Error retrieving file info');
                    }
                });
            }
        });
    }

    const handleCloseModalDownload = () => setShowModalDownload(false);

    const screenData = {
        title: t('View File'),
        subTitle: t('File'),
        mdiIcon: mdiFileDownload,
        backButton: {
            route: `/${locale}/explorer`,
            caption: t('Explorer'),
        },
    }
    
    useEffect(() => {
        getFile(fileId, data => setData(data));
    }, [fileId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    const renameInProgress = data.result?.status_rename === 'IN_PROGRESS';

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>
                
                {renameInProgress &&  <AlertRenameInProgress />}

                <Table bordered striped responsive className="detailTable mb-4">
                    <tbody>
                        <tr>
                            <th>{t('ID')}</th>
                            <td>#{data.result?.id}</td>
                        </tr>
                        <tr>
                            <th>{t('Filename')}</th>
                            <td>{data.result?.name}{renameInProgress && <Spinner animation="border" size="sm" className="ms-2" />}</td>
                        </tr>
                        <tr>
                            <th>{t('Zone')}</th>
                            <td><CellValue value={data.result?.zone} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Chapter')}</th>
                            <td><CellValue value={data.result?.chapter} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Study')}</th>
                            <td><CellValue value={data.result?.study} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Sensor')}</th>
                            <td><CellValue value={data.result?.sensor} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Classification')}</th>
                            <td><CellValue value={data.result?.classification} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('Extension')}</th>
                            <td><CellValue value={data.result?.extension} column={{ key: 'name' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('File size')}</th>
                            <td><CellValue value={data.result?.size} column={{ key: 'name', type: 'filesize' }} /></td>
                        </tr>
                        <tr>
                            <th>{t('MD5 hash')}</th>
                            <td><FormattedMD5Hash>{data.result?.md5_hash}</FormattedMD5Hash></td>
                        </tr>
                        <tr>
                            <th>{t('Published')}</th>
                            <td><Published value={data.result?.published} /></td>
                        </tr>
                        <tr>
                            <th>{t('Created')}</th>
                            <td><FormattedDate format="full">{data.result?.date_created}</FormattedDate></td>
                        </tr>
                        <tr>
                            <th>{t('Updated')}</th>
                            <td><FormattedDate format="full">{data.result?.date_modified}</FormattedDate></td>
                        </tr>
                    </tbody>
                </Table>

                <IconButtonSet>
                    <IconButton mdiIcon={mdiDownload} onClick={() => {downloadFile(fileId)}} disabled={renameInProgress}>{t('Download file')}</IconButton>
                    <IconButton mdiIcon={mdiLinkVariant} onClick={() => {handleShowModalDownload()}} disabled={renameInProgress}>{t('Show download info')}</IconButton>  
                    {userAccount.is_staff === true && <IconButton mdiIcon={mdiPencil} route={`/${locale}/admin/files/${fileId}/edit`} disabled={renameInProgress}>{t('Edit file')}</IconButton>}
                </IconButtonSet>

                <ModalDownload
                    show={showModalDownload}     
                    onClose={handleCloseModalDownload} 
                    fileData={fileData}
                    fileDownloadData={fileDownloadData}
                /> 
            </GenericDetailScreen>
        );
    }
}
