import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Form, InputGroup } from 'react-bootstrap'
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { mdiContentCopy } from '@mdi/js';

import { COPY_TEXT_DURATION } from '../const.js';
import IconButton from './IconButton';

const CopyInput = (props) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const targetText = useRef();
    const targetTooltipCopied = useRef(null);

    const isTextarea = Array.isArray(props.children);
    const contents = isTextarea ? props.children.join("\n") : props.children;

    let timeoutText = null;
    const handleClickCopy = () => {
        selectText();
        setIsCopied(true);
        if(timeoutText) {
             clearTimeout(timeoutText);
        }
        timeoutText = setTimeout(() => setIsCopied(false), COPY_TEXT_DURATION);
    };

    const selectText = () => {
        navigator.clipboard.writeText(contents);
        targetText.current.select();
        targetText.current.blur();
   }

    const handleClickInput = () => {
        selectText();
    }

    return (
        <>
            {props?.caption && <Form.Label>{props?.caption}</Form.Label>}
            <InputGroup className={`copyClipboard ` + props?.className} ref={targetTooltipCopied}>
                {props?.label && <InputGroup.Text>{props?.label}</InputGroup.Text> }
                <Form.Control
                    ref={targetText}
                    as={isTextarea ? 'textarea' : 'input'}
                    wrap={isTextarea ? 'off' : 'on'}
                    rows={isTextarea ? 5 : null}
                    type="text"
                    readOnly
                    value={contents}
                    className="copyCommand"
                    onClick={handleClickInput}
                    aria-label={props['aria-label'] ?? props?.label ?? props?.caption}
                />
                <IconButton ariaLabel={t('Copy to clipboard')} tooltip={t('Copy to clipboard')} mdiIcon={mdiContentCopy} variant="outline-primary" onClick={handleClickCopy} />
            </InputGroup>
            <Overlay target={targetTooltipCopied.current} show={isCopied} placement="bottom">
                <Tooltip className="copyClipboard" aria-live="polite">
                    {t('Copied!')}
                </Tooltip>
            </Overlay>
        </>
    )
}

export default CopyInput;
