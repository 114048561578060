import React  from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalMessage = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="lg"
            aria-labelledby="modalMessageTitle"
            aria-describedby="modalMessageText"
            centered
            show={props.showModal}
            onHide={() => {
                if(props.onHide && typeof(props.onHide) === 'function') {
                    props.onHide();
                }
                props.setShowModal(false)
            }}
        >
        <Modal.Header closeButton>
            <Modal.Title as="h1" className="h2" id="modalMessageTitle">
                {props.modalTitle ?? t('Error')}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalMessageText">
            <p>{props.modalText}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => props.setShowModal(false)}>
                {t('Close')}
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default ModalMessage;
