import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { mdiAccount, mdiPencil, mdiLogin } from '@mdi/js';

import { GenericDetailScreen } from './Generic.js';
import { UserAccountContext, StateContext } from '../App';
import IconButtonSet from './IconButtonSet'
import IconButton from './IconButton'
import Avatar from './Avatar';

const Profile = () => {
    const { t } = useTranslation();
    const userAccount = useContext(UserAccountContext);
    const { locale } = useContext(StateContext);

    const screenData = {
        title: t('Profile'),
        subTitle: t('User'),
        mdiIcon: mdiAccount,
    };

    return (
        <GenericDetailScreen data={screenData}>
            {
                userAccount ? 
                    <>
                        <Table bordered striped responsive className="profile detailTable mb-4">
                            <tbody>
                                <tr>
                                    <th>{t('ID')}</th>
                                    <td>#{userAccount.external_id}</td>
                                </tr>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <td>{userAccount.name}</td>
                                </tr>
                                <tr>
                                    <th>{t('Email')}</th>
                                    <td>{userAccount.email}</td>
                                </tr>
                                <tr>
                                    <th>{t('Profile picture')}</th>
                                    <td><Avatar /></td>
                                </tr>
                            </tbody>
                        </Table>

                        <IconButtonSet>
                            <IconButton mdiIcon={mdiPencil} onClick={() => {
                                const profileUrl = document.body.getAttribute('data-profile-url');
                                window.location.href = profileUrl;
                            }}>{t('Edit profile')}</IconButton>
                        </IconButtonSet>
                    </>
                :
                    <>
                        <p>{t('You need to be logged in to view this page. Use your Pleio account to log in.')}</p>
                        <IconButton mdiIcon={mdiLogin} variant="primary" route={`/${locale}/login`}>{t('Login')}</IconButton>
                    </>
            }
        </GenericDetailScreen>
    );
}
  
export default Profile;
