import { useTranslation } from 'react-i18next';

const FileSize = (props) => {
    const { i18n } = useTranslation();
    const locale = props.locale ?? i18n.language;
    const fileSizeStr = humanFileSize(props.children, locale);
    return fileSizeStr;
}

const humanFileSize = (bytes, locale) => {
    const thresh = 1024;
    const dp = 1;

    if(isNaN(bytes)) {
        bytes = 0;
    }
  
    if (Math.abs(bytes) < thresh) {
        return bytes + ' bytes';
    }
  
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**dp;
  
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    const value = parseFloat(bytes.toFixed(dp));
    const formattedValue = value.toLocaleString(locale);
    return formattedValue + ' ' + units[u];
}

export default FileSize;
