import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Icon from '@mdi/react'
import { mdiFileDownload, mdiLifebuoy, mdiHammerScrewdriver, mdiGauge, mdiFile, mdiWindTurbine, mdiSchool, mdiFileUpload, mdiLogin, mdiFileEdit, mdiAccount, mdiLogout } from '@mdi/js';

import { UserAccountContext, StateContext } from '../App';
import DismissableAlert from './DismissableAlert.js';
import Avatar from './Avatar';
import ModalMessage from './ModalMessage';
import ModalConfirm from './ModalConfirm';

const Layout = (props) => {
    const { t } = useTranslation();
      
    const userAccount = useContext(UserAccountContext);
    const {
        locale, setLocale, alert, setAlert, showModal, setShowModal, modalText,
        showModalConfirm, setShowModalConfirm, modalConfirmData
    } = useContext(StateContext);

    const navigate = useNavigate();

    const pathName = window.location.pathname;
    let pathWithoutLocale = null;
    if(pathName.substring(0, 4) === '/nl/' || pathName.substring(0, 4) === '/en/') {
        pathWithoutLocale = pathName.substring(3, pathName.length);
    }
    else if(pathName === '/nl' || pathName === '/en') {
        pathWithoutLocale = '';
    }
    else {
        pathWithoutLocale = pathName;
    }

    const changeLanguage = (event, newLocale) => {
        // Set locale
        setLocale(newLocale);

        // Navigate to language page
        navigate('/' + newLocale + pathWithoutLocale);

        // Prevent following href link of <a>
        event.preventDefault();
    }

    useEffect(() => {
        setAlert({});
        window.scrollTo(0, 0);
    }, [setAlert])

    return (
        <>
            <header>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand as={Link} to={`/${locale}`}>
                            <img src={process.env.PUBLIC_URL + '/static/images/rvo-logo-nl.svg'} alt={t('RVO logo')} width="180" height="72" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse className="justify-content-end">
                            <Nav id="basic-navbar-nav">
                                <Nav.Link className={pathWithoutLocale === '' || pathWithoutLocale === '/' || pathWithoutLocale === '/explorer' ? 'active' : ''} as={Link} to={`/${locale}/explorer`}><Icon path={mdiFileDownload} size={0.75} aria-hidden={true} /><span>{t('Explorer')}</span></Nav.Link>
                                <Nav.Link className={pathWithoutLocale === '/help' && 'active'} as={Link} to={`/${locale}/help`}><Icon path={mdiLifebuoy} size={0.75} aria-hidden={true} /><span>{t('Help')}</span></Nav.Link>
                                { (userAccount.is_staff === true) && 
                                        <NavDropdown className={pathWithoutLocale.substring(0, 7) === '/admin/' && 'active'} title={<><Icon path={mdiHammerScrewdriver} size={0.75} aria-hidden={true} /><span>{t('Admin Panel')}</span></>} id="basic-nav-dropdown">
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/dashboard' && 'active'} as={Link} to={`/${locale}/admin/dashboard`}><Icon path={mdiGauge} size={0.75} aria-hidden={true} /><span>{t('Dashboard')}</span></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/files' && 'active'} as={Link} to={`/${locale}/admin/files`}><Icon path={mdiFile} size={0.75} aria-hidden={true} /><span>{t('Files')}</span></NavDropdown.Item>
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/zones' && 'active'} as={Link} to={`/${locale}/admin/zones`}><Icon path={mdiWindTurbine} size={0.75} aria-hidden={true} /><span>{t('Zones')}</span></NavDropdown.Item>
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/studies' && 'active'} as={Link} to={`/${locale}/admin/studies`}><Icon path={mdiSchool} size={0.75} aria-hidden={true} /><span>{t('Studies')}</span></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/upload' && 'active'} as={Link} to={`/${locale}/admin/upload`}><Icon path={mdiFileUpload} size={0.75} aria-hidden={true} /><span>{t('Upload Files')}</span></NavDropdown.Item>
                                            <NavDropdown.Item className={pathWithoutLocale === '/admin/files/unprocessed' && 'active'} as={Link} to={`/${locale}/admin/files/unprocessed`}><Icon path={mdiFileEdit} size={0.75} aria-hidden={true} /><span>{t('Unprocessed Files')}</span></NavDropdown.Item>
                                        </NavDropdown>
                                } 
                                { (userAccount) ?      
                                        <NavDropdown title={
                                            <>
                                                <Icon path={mdiAccount} size={0.75} aria-hidden={true} className="userIcon" />
                                                <Navbar.Text>
                                                    <Avatar />
                                                </Navbar.Text>
                                                <div>{userAccount.name}</div>
                                            </>
                                        } align="end" id="nav-account-dropdown">
                                            <NavDropdown.Item as={Link} to={`/${locale}/profile`}><Icon path={mdiAccount} size={0.75} aria-hidden={true} /><span>{t('Profile')}</span></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} to={`/${locale}/logout`}><Icon path={mdiLogout} size={0.75} aria-hidden={true} /><span>{t('Log out')}</span></NavDropdown.Item>
                                        </NavDropdown>
                                :        
                                    (!userAccount) && <Nav.Link as={Link} to={`/${locale}/login`} disabled={userAccount === null}><Icon path={mdiLogin} size={0.75} aria-hidden={true} /><span>{t('Log in')}</span></Nav.Link> 
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>

            <ModalConfirm
                showModalConfirm={showModalConfirm}
                setShowModalConfirm={setShowModalConfirm}
                modalConfirmData={modalConfirmData}
                modalConfirmCallback={props.modalConfirmCallback}
            />

            <main className="page container">
                {
                    alert.text &&
                    <DismissableAlert variant="warning">
                        {alert.text}
                    </DismissableAlert>
                }

                {props.children}

            </main>

            <footer>
                <Navbar>
                    <Container className="justify-content-end">
                        <Nav>
                            {locale === 'en' ?
                                <>
                                    <Nav.Link as="div" className="active">English</Nav.Link>
                                    <Nav.Link href={`/nl${pathWithoutLocale}`} onClick={event => changeLanguage(event, 'nl')}>Nederlands</Nav.Link>
                                </>
                                :
                                <>
                                    <Nav.Link href={`/en${pathWithoutLocale}`} onClick={event => changeLanguage(event, 'en')}>English</Nav.Link>
                                    <Nav.Link as="div" className="active">Nederlands</Nav.Link>
                                </>
                            }
                        </Nav>
                    </Container>
                </Navbar>
            </footer>

            <ModalMessage
                showModal={showModal}
                setShowModal={setShowModal}
                modalText={modalText}
            />
        </>
    )
}
  
export default Layout;
