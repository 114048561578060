import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Icon from '@mdi/react';
import { mdiClose, mdiFilter, mdiSortVariant } from '@mdi/js';

import IconButton from '../IconButton'

const ResultHeader = (props) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const targetSearchQuery = useRef(null);

    const searchQueryInputHandler = (event) => {
        const searchQuery = event.target.value;
        setSearchQuery(searchQuery);
        props.updateSearchQuery(searchQuery);
    }

    const searchQueryReset = (event) => {
        props.updateSearchQuery('');
        setSearchQuery('');
        targetSearchQuery.current.focus();
    }

    if(!props.controlItems || props.controlItems.length === 0) {
        return <div className="resultTableHeader"></div>;
    }

    // Filter count
    let filterCount = 0;
    if(props.filter) {
        for (const item in props.filter) {
            if(Array.isArray(props.filter[item])) {
                if(props.filter[item].length > 0) {
                    filterCount += props.filter[item].length;
                }
            }
            else {
                if(props.filter[item]) {
                    filterCount++;
                }
            }
        }
    }

    return (
        <div className="resultTableHeader">
            {
                props.controlItems.map((item, i) => {
                    if (typeof item === 'string' || item instanceof String) {
                        item = { type: item };
                    }

                    if(item.type === 'search') {
                        return (
                            <div key={i}>
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control
                                            ref={targetSearchQuery}
                                            value={searchQuery}
                                            type="text"
                                            placeholder={t('Keyword(s)')}
                                            aria-label={t('Keyword(s)')}
                                            size="md"
                                            onChange={searchQueryInputHandler}
                                            id="search-query-input"
                                        />
                                        <Button className="search-query-clear-input" variant="outline-primary" onClick={searchQueryReset} title={t('Clear input')} aria-label={t('Clear input')}>
                                            <Icon path={mdiClose} size={0.75} color="black" />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        )
                    }
                    else if(item.type === 'filter') {
                        return <div key={i} title={t('Filter')}>
                            <IconButton
                                key={i}
                                mdiIcon={mdiFilter}
                                variant="primary"
                                onClick={() => { props.setModalFilterShow(true); }}
                                size={0.75}
                                color="white"
                                aria-label={t('Filter')}
                            >
                                {filterCount ? t('Filter ({{filterCount}})', { filterCount }) : t('Filter')}
                            </IconButton>
                        </div>
                    }
                    else if(item.type === 'sort') {
                        return <div key={i} title={t('Ordering')}>
                            <IconButton
                                key={i}
                                mdiIcon={mdiSortVariant}
                                variant="primary"
                                onClick={() => { props.setModalSortShow(true); }}
                                size={0.75}
                                color="white"
                                aria-label={t('Ordering')}
                            >
                                {t('Ordering')}
                            </IconButton>
                        </div>
                    }
                    else if(item.type === 'button') {
                        return <div key={i} title={item.caption}>
                            <IconButton
                                key={i}
                                mdiIcon={item.mdiIcon}
                                variant={item.variant ?? 'primary'}
                                route={item.route}
                                size={0.75}
                                color="white"
                            >
                                {item.caption}
                            </IconButton>
                        </div>
                    }
                    else {
                        return <div key={i}>{item.type}</div>
                    }
                })
            }
        </div>
    );
}

export default ResultHeader;
