import React  from 'react';
import { useTranslation } from 'react-i18next';

const Published = (props) => {
    const { t } = useTranslation();
    if(typeof(props.value) === 'undefined') {
        return null;
    }
    const caption = props.value === true ? t('Published') : t('Not published');
    let className = props.value === true ? 'isPublished' : 'isNotPublished';
    if(props.className) {
        className += ' ' + props.className;
    }
    return <span className={className}>{caption}</span>
}
  
export default Published;
