import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiArrowLeft } from '@mdi/js';

import { StateContext } from '../App';
import Layout from './Layout';
import PageHeader from './PageHeader';
import IconButtonSet from './IconButtonSet';
import IconButton from './IconButton';

export const GenericDetailScreen = (props) => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);

    let path = props.data.path;
    if(Array.isArray(path) && path.length) {
        path.unshift({caption: t('Admin Panel'), route: `/${locale}/admin/dashboard`});
    }

    let backButton = props.data.backButton;
    if(backButton === true && path.length > 0) {
        backButton = path[path.length - 1];
    }

    return (
        <Layout>
            <PageHeader mdiIcon={props.data.mdiIcon} path={path}>
                {props.data.title}
            </PageHeader>

            <div className="genericScreenBackButton">
                {backButton && <IconButton mdiIcon={mdiArrowLeft} route={backButton.route}>{backButton.caption}</IconButton>}
            </div>

            <div className="area">
                {props.data.subTitle && <h2>{props.data.subTitle}</h2>}
                {props.children}
            </div>
        </Layout>
    );
}

export const GenericDetailError = (props) => {
    let errorMessage = null;
    if(props.data?.status === 404) {
        errorMessage = props.messageNotFound;
    }
    else {
        errorMessage = props.data?.message + ' (' + props.data?.status + ')';
    }

    return (
        <>
            <p>{errorMessage}</p>
            {
                props.buttons && props.buttons.length &&
                    <IconButtonSet>
                        {
                            props.buttons.map((button, i) => <IconButton key={i} mdiIcon={button.mdiIcon} route={button.route}>{button.caption}</IconButton>)
                        }
                    </IconButtonSet>
            }
        </>
    );
}
