import React  from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const ModalSort = (props) => {
    const { t } = useTranslation();
    const [sortingColumnRadioChecked, setSortingColumnRadioChecked] = React.useState(props.sortingColumn);
    const [sortingOrderRadioChecked, setSortingOrderRadioChecked] = React.useState(props.sortingOrderAscending);

    const handleChangeSortingColumn = (event) => {
        setSortingColumnRadioChecked(event.target.value);
        props.updateSort(event.target.value, sortingOrderRadioChecked);
    }

    const handleChangeSortingOrder = (event) => {
        const isAscending = event.target.value === 'asc';
        setSortingOrderRadioChecked(isAscending);
        props.updateSort(sortingColumnRadioChecked, isAscending);
    }

    return (
        <Modal
            size="md"
            aria-labelledby="modalSortTitle"
            centered
            show={props.modalSortShow}
            onHide={() => props.setModalSortShow(false)}
        >
        <Modal.Header closeButton>
            <Modal.Title as="h1" id="modalSortTitle" className="h2">
                {t('Ordering')}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <h2 className="h5">{t('Column')}</h2>
                { props.columns.map(
                    (item, i) =>
                        <div key={i}>
                            {
                                (typeof(item.sort) === 'undefined' || item.sort === true) &&
                                    <Form.Check
                                        type="radio"
                                        name="sort-column"
                                        id={`checkbox-${item.id}`}
                                        value={item.id}
                                        label={item.caption}
                                        defaultChecked={item.id === sortingColumnRadioChecked}
                                        onChange={handleChangeSortingColumn}
                                    />
                            }
                        </div>
                    )
                }
                <hr/>

                <h2 className="h5">{t('Order')}</h2>
                <Form.Check inline type="radio" name="sort-order" id="order-asc" value="asc" label={t('Ascending (A-Z)')} defaultChecked={sortingOrderRadioChecked === true} onChange={handleChangeSortingOrder} />
                <Form.Check inline type="radio" name="sort-order" id="order-desc" value="desc" label={t('Descending (Z-A)')} defaultChecked={sortingOrderRadioChecked === false} onChange={handleChangeSortingOrder} />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => props.setModalSortShow(false)}>
                {t('Close')}
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default ModalSort;
