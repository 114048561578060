import React, { useContext, useEffect }  from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Icon from '@mdi/react'
import { mdiGauge, mdiSchool, mdiFile, mdiWindTurbine, mdiFileEdit, mdiFileUpload, mdiChevronRight } from '@mdi/js';

import { retrieveData } from '../../data';
import { API_ENDPOINT_TOTALS } from '../../const';
import { StateContext } from '../../App';
import Layout from '../Layout';
import PageHeader from '../PageHeader';
import FormattedNumber from '../FormattedNumber';


const Dashboard = () => {
    const { t } = useTranslation();
    const [data, setData] = React.useState(null);
    const { locale } = useContext(StateContext);

    useEffect(() => {
        // Call endpoint to retrieve data
        retrieveData(API_ENDPOINT_TOTALS, {}, (data) => {
            setData(data);
            if(data.success === false) {
                data.result = {
                    published_studies: null,
                    published_files: null,
                    unprocessed_files: null,
                    published_zones: null,
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <PageHeader mdiIcon={mdiGauge} path={[{caption: t('Admin Panel'), route: `/${locale}/admin/dashboard`}]}>{t('Dashboard')}</PageHeader>

            <div className="row gy-4">
                <div className="col-md-4">
                    <div className="area infoBox">
                        <div className="header">
                            <h2>{t('Studies')}</h2>
                            <div className="icon">
                                <NavLink to={`/${locale}/admin/studies`}><Icon path={mdiSchool} size={1.5} color="#504e58" aria-label={t('Studies')} /></NavLink>
                            </div>
                        </div>
                        <div className="number">
                            {data === null ? <Spinner animation="border" size="sm" /> : <FormattedNumber>{data?.result?.published_studies}</FormattedNumber>}
                        </div>
                        <div className="link">
                            <NavLink to={`/${locale}/admin/studies`}>
                                {t('View all studies')}<Icon path={mdiChevronRight} size={0.75} color="#02689b" aria-hidden={true} />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="area infoBox">
                        <div className="header">
                            <h2>{t('Files')}</h2>
                            <div className="icon">
                                <NavLink to={`/${locale}/admin/files`}><Icon path={mdiFile} size={1.5} color="#504e58" aria-label={t('Files')} /></NavLink>
                            </div>
                        </div>
                        <div className="number">
                            {data === null ? <Spinner animation="border" size="sm" /> : <FormattedNumber>{data?.result?.published_files}</FormattedNumber>}
                        </div>
                        <div className="link">
                            <NavLink to={`/${locale}/admin/files`}>
                                {t('View all files')}<Icon path={mdiChevronRight} size={0.75} color="#02689b" aria-hidden={true} />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="area infoBox">
                        <div className="header">
                            <h2>{t('Zones')}</h2>
                            <div className="icon">
                                <NavLink to={`/${locale}/admin/zones`}><Icon path={mdiWindTurbine} size={1.5} color="#504e58" aria-label={t('Zones')} /></NavLink>
                            </div>
                        </div>
                        <div className="number">
                            {data === null ? <Spinner animation="border" size="sm" /> : <FormattedNumber>{data?.result?.published_zones}</FormattedNumber>}
                        </div>
                        <div className="link">
                            <NavLink to={`/${locale}/admin/zones`}>
                                {t('View all zones')}<Icon path={mdiChevronRight} size={0.75} color="#02689b" aria-hidden={true} />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="area infoBox">
                        <div className="header">
                            <h2>{t('Unprocessed Files')}</h2>
                            <div className="icon">
                                <NavLink to={`/${locale}/admin/files/unprocessed`}><Icon path={mdiFileEdit} size={1.5} color="#504e58" aria-label={t('Unprocessed Files')} /></NavLink>
                            </div>
                        </div>
                        <div className="number">
                            {data === null ? <Spinner animation="border" size="sm" /> : <FormattedNumber>{data?.result?.unprocessed_files}</FormattedNumber>}
                        </div>
                        <div className="link">
                            <NavLink to={`/${locale}/admin/files/unprocessed`}>
                                {t('View unprocessed files')}<Icon path={mdiChevronRight} size={0.75} color="#02689b" aria-hidden={true} />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="area infoBox">
                        <div className="header">
                            <h2>{t('Upload Files')}</h2>
                            <div className="icon">
                                <NavLink to={`/${locale}/admin/upload`}><Icon path={mdiFileUpload} size={1.5} color="#504e58" aria-label={t('Upload Files')} /></NavLink>
                            </div>
                        </div>
                        <div className="text">
                            <p>{t('Click the link below for instructions to upload new files to the Azure storage.')}</p>
                        </div>
                        <div className="link">
                            <NavLink to={`/${locale}/admin/upload`}>
                                {t('Upload Files')}<Icon path={mdiChevronRight} size={0.75} color="#02689b" aria-hidden={true} />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
  
export default Dashboard;
