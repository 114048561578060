import React  from 'react';
import Spinner from 'react-bootstrap/Spinner';

const IconButtonSet = (props) => {
    return (
        <div className="iconButtonSet">
            {props.children}
            {props.showSpinner && <div className="formSpinner"><Spinner animation="border" variant="primary" /></div>}
        </div>
    );
}
  
export default IconButtonSet;
