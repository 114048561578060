import axios from 'axios';
import { API_ENDPOINT, API_ENDPOINT_FILES } from './const.js';

let cancelRequest = []; 

// Get data
export const retrieveData = (endpoint, params, callback) => {
    if (cancelRequest[endpoint] ) {
        // Cancel previous call to this endpoint if there is one stil running
        cancelRequest[endpoint].cancel(); 
    }
    // Create a new cancel token for the new ajax call
    cancelRequest[endpoint] = axios.CancelToken.source();

    if(!params) {
        params = {};
    }

    // Execute the GET ajax call
    axios.get(endpoint, {
        params: params,
        paramsSerializer: { indexes: null },        // Ensure multiple parameters with the same name are formatted correctly for Django
        withCredentials: false,
        cancelToken: cancelRequest[endpoint].token
    })
        .then(response => {
            // Reset cancel token
            delete cancelRequest[endpoint];

            // Prepare data to pass to the callback
            if(typeof(callback) === 'function') {
                const data = {
                    success: true,
                    status: response.status,
                };
                if(response.data?.results) {
                    // Multiple results
                    data.count = response.data.count;
                    data.result = response.data.results;
                }
                else {
                    // Single result
                    data.count = 1;
                    data.result = response.data;
                }

                callback(data);
            }
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                // Previous request canceled, new request is sent
            }
            else {
                if(typeof(callback) === 'function') {
                    const data = {
                        result: [],
                        success: false,
                        status: error?.response?.status,
                        statusText: error?.response?.statusText,
                        message: error?.message,
                    }
                    callback(data);
                }
            }
        })
}

export const saveData = (endpoint, primaryKey, data, callback, method) => {
    // Setup request
    const url = endpoint + (primaryKey > 0 ? primaryKey + '/' : '');
    if(typeof(method) === 'undefined') {
        method = primaryKey > 0 ? 'PUT' : 'POST';
    }

    const csrfToken = document.body.getAttribute('data-django-csrf-token');
    const options = {
        url: url,
        method: method,
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
        }
    };

    // Do request
    axios(options)
        .then(response => {
            if(typeof(callback) === 'function') {
                response.success = true;
                callback(response);
            }
        })
        .catch(error => {
            if(typeof(callback) === 'function') {
                callback({
                    success: false,
                    error: error,
                });
            }
       })   
}

export const deleteData = (endpoint, keyParam, callback) => {
    let arrayIds = [];
    let url = endpoint;
    let data = null;
    if(Array.isArray(keyParam)) {
        arrayIds = keyParam;
        data = { ids: arrayIds };
    }
    else {
        const primaryKey = keyParam;
        url += primaryKey + '/';
    }

    // Setup request
    const csrfToken = document.body.getAttribute('data-django-csrf-token');
    const options = {
        url: url,
        method: 'DELETE',
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
        }
    };

    // Do request
    axios(options)
        .then(response => {
            if(typeof(callback) === 'function') {
                response.success = true;
                callback(response);
            }
        })
        .catch(error => {
            if(typeof(callback) === 'function') {
                callback({
                    result: null,
                    success: false,
                    status: error.response.status,
                    statusText: error.response.statusText,
                    message: error.message,
                });
            }
       })   
}

export const getDropdownData = (endpoints, setDropdownData, flagUnpublished) => {
    for(let i = 0; i < endpoints.length; i++) {
        const key = endpoints[i];
        const endpoint = API_ENDPOINT[key];

        let params = {
            pagination: 'false',
            ordering: 'name',
        };
        if(flagUnpublished === true) {
            params.also_unpublished = 'true';
        }

        retrieveData(endpoint, params, (data) => {
            setDropdownData(dropdownData => ({
                ...dropdownData,
                [key]: data.success === true ? data.result : null,
            }));
    });
    }
}

export const downloadFile = (primaryKey) => {
    axios({
        url: API_ENDPOINT_FILES + primaryKey + '/download/',
        method: 'GET',
    })
    .then(response => {
        const url = response?.data?.download_url;
        if (url) {
           window.location.href = url; 
        }
    })
    .catch(error => {
        console.error(error);
    });
}
