import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiFileEdit, mdiContentSave, mdiCancel, mdiPencil } from '@mdi/js';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';

import { API_ENDPOINT_FILES } from '../../const.js';
import { retrieveData, saveData, getDropdownData } from '../../data.js';
import { StateContext } from '../../App';
import { GenericDetailScreen, GenericDetailError } from '../Generic.js';
import Layout from '../Layout';
import Result from '../Result/Result';
import PageHeader from '../PageHeader';
import IconButtonSet from '../IconButtonSet';
import IconButton from '../IconButton';
import LoadingSpinner from '../LoadingSpinner.js';
import Published from '../Published';
import FormattedDate from '../FormattedDate';
import FormattedMD5Hash from '../FormattedMD5Hash';
import FileSize from '../FileSize';
import AlertRenameInProgress from '../AlertRenameInProgress';

export const UnprocessedFiles = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);
    const [refreshData, setRefreshData] = React.useState(false);

    const columns = [
        // { id: 'checkbox', type: 'checkbox', sort: false, filter: false },
        { id: 'name', type: 'filename', route: `/${locale}/admin/files/unprocessed/:id/view`, caption: t('Filename'), width: '50%', class: 'width-50p-sm', filter: false },
        { id: 'extension', key: 'name', caption: t('Extension'), class: 'hide-xl' },
        { id: 'size', type: 'filesize', caption: t('Size'), align: 'right', width: '6rem', class: 'hide-lg', filter: false },
        { id: 'published', type: 'boolean', width: '7em', caption: t('Published') },
        { id: 'date_created', type: 'datetime', caption: t('Created'), width: '8em', class: 'hide-lg', filter: false },
        { id: 'date_modified', type: 'datetime', caption: t('Updated'), width: '8em', class: 'hide-lg', filter: false },
        { id: 'actions', type: 'actions', caption: 'Acties',
            actions: [
                {type: 'view', title: t('View file'), route: `/${locale}/admin/files/unprocessed/:id/view`},
                {type: 'edit', title: t('Edit file'), route: `/${locale}/admin/files/unprocessed/:id/edit`},
            ],
            sort: false,
            filter: false
        },
    ];

    const controlItems = [
        'search',
        'filter',
        'sort',
    ]

    return (
        <Layout>
            <PageHeader mdiIcon={mdiFileEdit} path={[{caption: t('Admin Panel'), route: `/${locale}/admin`}]}>{t('Unprocessed Files')}</PageHeader>
            <Result
                endpoint={API_ENDPOINT_FILES}
                columns={columns}
                controlItems={controlItems}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                filter={{ 'only_unprocessed': 'true'/*, 'also_unpublished': 'true'*/ }}
            />
        </Layout>
    )
}

const getFile = (fileId, setter, callback) => {
    fileId = parseInt(fileId);
    if(fileId > 0) {
        const url = API_ENDPOINT_FILES + fileId + '/';
        retrieveData(url, { 'only_unprocessed': 'true'/*, 'also_unpublished': 'true'*/ }, (data) => {
            setter(data);
            if(typeof(callback) === 'function') {
                callback(data);
            }
        });
    }
    else {
        // Don't bother trying to retrieve a record for an ID <= 0, immediately return 404
        setter({result: null, success: false, status: 404});
    }
};

export const UnprocessedFilesEdit = (props) => {
    const { fileId } = useParams();
    const action = props.action;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale, setModalText, setShowModal } = useContext(StateContext);

    const [formIsDisabled, setFormIsDisabled] = useState(fileId > 0 ? true : false);
    const [formIsSaving, setFormIsSaving] = useState(false);
    const [data, setData] = React.useState(null);
    const [dropdownData, setDropdownData] = React.useState({});

    const [showTooltipFileName, setShowTooltipFileName] = useState(false);
    const [showTooltipZone, setShowTooltipZone] = useState(false);
    const [showTooltipChapter, setShowTooltipChapter] = useState(false);
    const [showTooltipStudy, setShowTooltipStudy] = useState(false);
    const [showTooltipSensor, setShowTooltipSensor] = useState(false);
    const [showTooltipClassification, setShowTooltipClassification] = useState(false);

    const [fileName, setFileName] = React.useState('');
    const [fileZone, setFileZone] = React.useState('');
    const [fileChapter, setFileChapter] = React.useState('');
    const [fileStudy, setFileStudy] = React.useState('');
    const [fileClassification, setFileClassification] = React.useState('');
    const [fileSensor, setFileSensor] = React.useState('');
    const [filePublished, setFilePublished] = React.useState(false);

    const targetName = useRef(null);
    const targetZone = useRef(null);
    const targetChapter = useRef(null);
    const targetStudy = useRef(null);
    const targetClassification = useRef(null);
    const targetSensor = useRef(null);
    const targetNameLabel = useRef(null);
    const targetZoneLabel = useRef(null);
    const targetChapterLabel = useRef(null);
    const targetStudyLabel = useRef(null);
    const targetClassificationLabel = useRef(null);
    const targetSensorLabel = useRef(null);

    const screenData = {
        title: action === 'edit' && fileId > 0 ? t('Edit file') : t('New file'),
        subTitle: fileId > 0 ? t('File (#{{id}})', { id: fileId}) : t('File'),
        mdiIcon: mdiFileEdit,
        path: [
            {caption: t('Files'), route: `/${locale}/admin/files`},
        ],
    }

    const submitForm = (event, fileId) => {
        event.preventDefault();

        if (!fileName) {
            // Filename is required
            setShowTooltipFileName(true);
            targetName.current.focus();
            return false;
        }

        if (!fileZone) {
            // Zone is required
            setShowTooltipZone(true);
            targetZone.current.focus();
            return false;
        }

        if (!fileChapter) {
            // Chapter is required
            setShowTooltipChapter(true);
            targetChapter.current.focus();
            return false;
        }

        if (!fileStudy) {
            // Study is required
            setShowTooltipStudy(true);
            targetStudy.current.focus();
            return false;
        }

        if (!fileClassification) {
            // Classification is required            
            setShowTooltipClassification(true);
            targetClassification.current.focus();
            return false;
        }
    
        // Disable form and show spinner
        setFormIsDisabled(true);
        setFormIsSaving(true);
    
        // Setup payload
        const payload = {
            name: fileName,
            zone: parseInt(fileZone),
            chapter: parseInt(fileChapter),
            study: parseInt(fileStudy),
            classification: parseInt(fileClassification),
            sensor: parseInt(fileSensor),
            published: !!filePublished,
            processed: 'true',
        };

        // Send data
        saveData(API_ENDPOINT_FILES, fileId, payload, (response) => {
            // Enable form and hide spinner
            setFormIsDisabled(false);
            setFormIsSaving(false);

            if(response.success === true) {
                // Success
                if(!fileId) {
                    fileId = response.data.id;
                }

                // Navigate to list
                navigate(`/${locale}/admin/files/unprocessed/`);
            }
            else {
                // Failure
                setModalText(response?.error?.message ?? 'Error');
                setShowModal(true);
            }    
        });
    }

    const handleChangeFileName = (event) => {
        setFileName(event.target.value);
        setShowTooltipFileName(false);
    }

    const handleChangeFileZone = (event) => {
        setFileZone(event.target.value);
        setShowTooltipZone(false);
    }

    const handleChangeFileChapter = (event) => {
        setFileChapter(event.target.value);
        setShowTooltipChapter(false);
    }

    const handleChangeFileStudy = (event) => {
        setFileStudy(event.target.value);
        setShowTooltipStudy(false);
    }

    const handleChangeFileClassification = (event) => {
        setFileClassification(event.target.value);
        setShowTooltipClassification(false);
    }

    const handleChangeFileSensor = (event) => {
        setFileSensor(event.target.value);
        setShowTooltipSensor(false);
    }

    const handleChangeFilePublished = (event) => {
        setFilePublished(!!event.target.checked);
    }
    
    const getData = (fileId) => {
        getFile(fileId, (data) => {
            setData(data);
            if(data.success === true) {
                // Set form values
                setFileName(data.result?.name);
                setFileZone(data.result?.zone?.id);
                setFileChapter(data.result?.chapter?.id);
                setFileStudy(data.result?.study?.id);
                setFileClassification(data.result?.classification?.id);
                setFileSensor(data.result?.sensor?.id);
                setFilePublished(!!data.result?.published);
            }

            // Enable form
            setFormIsDisabled(false);
        });        
    };

    useEffect(() => {
        if(action === 'edit') {
            getData(fileId);
        }
        else {
            setData({ success: true });
        }
    }, [action, fileId]);    

    useEffect(() => {
        getDropdownData(['zone', 'chapter', 'study', 'classification', 'sensor'], setDropdownData, true);
    }, []);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    const renameInProgress = data.result?.status_rename === 'IN_PROGRESS';

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>

                {renameInProgress &&  <AlertRenameInProgress />}

                <Form onSubmit={event => submitForm(event, fileId)} name="FileForm">
                    <fieldset disabled={formIsDisabled}>
                        <FloatingLabel controlId="fileName" ref={targetNameLabel} label={t('Name')} className="mb-4">
                            <Form.Control ref={targetName} type="text" value={fileName} placeholder={t('Filename')} onChange={handleChangeFileName} onBlur={() => { setShowTooltipFileName(false) }} disabled={renameInProgress} autoFocus />
                        </FloatingLabel>
                        <Overlay target={targetNameLabel.current} show={showTooltipFileName} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please enter a filename.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileZone" ref={targetZoneLabel} label={t('Zone')} className="mb-4">
                            <Form.Select ref={targetZone} onChange={handleChangeFileZone} onBlur={() => { setShowTooltipZone(false) }} value={fileZone} disabled={renameInProgress}>
                                <option value="">{t('(None)')}</option>
                                {
                                    dropdownData?.zone &&
                                        dropdownData.zone.map((rec, k) =>
                                            <option value={rec.id} key={k}>{rec.name}</option>
                                        )
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetZoneLabel.current} show={showTooltipZone} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a zone.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileChapter" ref={targetChapterLabel} label={t('Chapter')} className="mb-4">
                            <Form.Select ref={targetChapter} onChange={handleChangeFileChapter} onBlur={() => { setShowTooltipChapter(false) }} value={fileChapter} disabled={renameInProgress}>
                                <option value="">{t('(None)')}</option>
                                {
                                    dropdownData?.chapter &&
                                        dropdownData.chapter.map((rec, k) =>
                                            <option value={rec.id} key={k}>{rec.name}</option>
                                        )
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetChapterLabel.current} show={showTooltipChapter} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a chapter.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileStudy" ref={targetStudyLabel} label={t('Study')} className="mb-4">
                            <Form.Select ref={targetStudy} onChange={handleChangeFileStudy} onBlur={() => { setShowTooltipStudy(false) }} value={fileStudy} disabled={renameInProgress}>
                                {
                                    dropdownData?.study &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.study.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetStudyLabel.current} show={showTooltipStudy} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a study.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileClassification" ref={targetClassificationLabel} label={t('Classification')} className="mb-4">
                            <Form.Select ref={targetClassification} onChange={handleChangeFileClassification} onBlur={() => { setShowTooltipClassification(false) }} value={fileClassification} disabled={renameInProgress}>
                                {
                                    dropdownData?.classification &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.classification.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetClassificationLabel.current} show={showTooltipClassification} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a classification.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="fileSensor" ref={targetSensorLabel} label={t('Sensor')} className="mb-4">
                            <Form.Select ref={targetSensor} onChange={handleChangeFileSensor} onBlur={() => { setShowTooltipChapter(false) }} value={fileSensor} disabled={renameInProgress}>
                                {
                                    dropdownData?.sensor &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.sensor.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetSensorLabel.current} show={showTooltipSensor} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a sensor.')}
                            </Tooltip>
                        </Overlay>

                        <div className="mb-4">
                            <Form.Label>{t('Publication status')}</Form.Label>
                            <Form.Switch 
                                defaultChecked={filePublished}
                                onChange={handleChangeFilePublished}
                                id="filePublished"
                                label={t('File is published to website')}
                                disabled={renameInProgress}
                            />
                        </div>

                        <hr />

                        <IconButtonSet showSpinner={formIsSaving}>
                            <IconButton mdiIcon={mdiContentSave} onClick={(event) => submitForm(event, fileId)} disabled={renameInProgress}>{t('Save')}</IconButton>
                            <IconButton mdiIcon={mdiCancel} variant="primary" route={`/${locale}/admin/files/unprocessed`}>{t('Cancel')}</IconButton>
                        </IconButtonSet>
                    </fieldset>
                </Form>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <UnprocessedDetailError data={data} />
        </GenericDetailScreen>
    )
}

export const UnprocessedFilesView = () => {
    const { fileId } = useParams();
    const { t } = useTranslation();
    const [data, setData] = React.useState(null);
    const { locale } = useContext(StateContext);

    const screenData = {
        title: t('View file'),
        subTitle: t('Unprocessed file'),
        mdiIcon: mdiFileEdit,
        path: [
            { caption: t('Unprocessed Files'), route: `/${locale}/admin/files/unprocessed` },
        ],
        backButton: true,
    }
    
    useEffect(() => {
        getFile(fileId, data => setData(data));
    }, [fileId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>
                <Table bordered striped responsive className="detailTable mb-4">
                    <tbody>
                        <tr>
                            <th>{t('ID')}</th>
                            <td>#{data.result?.id}</td>
                        </tr>
                        <tr>
                            <th>{t('Filename')}</th>
                            <td>{data.result?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Zone')}</th>
                            <td>{data.result?.zone?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Chapter')}</th>
                            <td>{data.result?.chapter?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Study')}</th>
                            <td>{data.result?.study?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Classification')}</th>
                            <td>{data.result?.classification?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Sensor')}</th>
                            <td>{data.result?.sensor?.name ?? '-'}</td>
                        </tr>
                        <tr>
                            <th>{t('File size')}</th>
                            <td><FileSize>{data.result?.size}</FileSize></td>
                        </tr>
                        <tr>
                            <th>{t('MD5 hash')}</th>
                            <td><FormattedMD5Hash>{data.result?.md5_hash}</FormattedMD5Hash></td>
                        </tr>
                        <tr>
                            <th>{t('Published')}</th>
                            <td><Published value={data.result?.published} /></td>
                        </tr>
                        <tr>
                            <th>{t('Created')}</th>
                            <td><FormattedDate format="full">{data.result?.date_created}</FormattedDate></td>
                        </tr>
                        <tr>
                            <th>{t('Updated')}</th>
                            <td><FormattedDate format="full">{data.result?.date_modified}</FormattedDate></td>
                        </tr>
                    </tbody>
                </Table>

                <IconButtonSet>
                    <IconButton mdiIcon={mdiPencil} route={`/${locale}/admin/files/unprocessed/${fileId}/edit`}>{t('Edit file')}</IconButton>
                </IconButtonSet>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <UnprocessedDetailError data={data} />
        </GenericDetailScreen>
    )
}

const UnprocessedDetailError = (props) => {
    const { t } = useTranslation();

    return (
        <GenericDetailError
            data={props.data}
            messageNotFound={t('This file does not exist (anymore).')}
        />
    )
}
