import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import $ from 'jquery';

import { retrieveData } from '../../data.js';
import ResultHeader from './ResultHeader'
import ResultBody from './ResultBody'
import ModalFilter from '../ModalFilter';
import ModalSort from '../ModalSort';

const Result = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = React.useState(null);
    const [sortingColumn, setSortingColumn] = React.useState('date_modified');
    const [sortingOrderAscending, setSortingOrderAscending] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filter, setFilter] = React.useState({});
    const [modalFilterShow, setModalFilterShow] = React.useState(false);
    const [modalSortShow, setModalSortShow] = React.useState(false);
    const [tableBusy, setTableBusy] = React.useState(false);
    const [selectAllCheckboxStatus, setSelectAllCheckboxStatus] = React.useState(false);
    const [checkboxStatus, setCheckboxStatus] = React.useState({});

    const itemsPerPage = 25;

    const updateSort = (newSortingColumn, newSortingOrderAscending) => {
        // Set sorting order
        if(typeof(newSortingOrderAscending) === 'undefined') {
            // If the user selects a different column, reset the order back
            newSortingOrderAscending = newSortingColumn !== sortingColumn ? true : !sortingOrderAscending;
        }

        // Set new sorting settings
        setSortingColumn(newSortingColumn);
        setSortingOrderAscending(newSortingOrderAscending);

        // Reset to first page
        updateCurrentPage(1);
    }

    const updateFilter = (filterName, filterValue) => {
        if(filterValue === 'true' || filterValue === 'false') {
            // Convert string value to boolean value
            filterValue = filterValue === 'true';
        }
        else if(!Array.isArray(filterValue)) {
            if(!filterValue || isNaN(filterValue)) {
                filterValue = null;
            }
        }   

        // Set state of filter object, keeping existing keys
        setFilter(filter => ({ ...filter, [filterName]: filterValue }));

        // Reset to first page
        updateCurrentPage(1);
    }

    const updateSearchQuery = (newSearchQuery) => {
        // Set the search query
        setSearchQuery(newSearchQuery);

        // Reset to first page
        updateCurrentPage(1);
    }

    const updateCurrentPage = (newCurrentPage) => {
        // Figure out the scroll position of the result table header
        const target = $( $('.resultTableHeader') );
        const targetPosition = target.offset().top - 16;

        // Current scroll position
        const currentPosition = window.pageYOffset;

        // Scroll to the top of the table if the current scroll position is below
        if(currentPosition > targetPosition) {
            $('html, body').animate({ scrollTop: targetPosition }, 150);
        }

        // Switch to the preferred page
        setCurrentPage(newCurrentPage);

        // Set select all to false
        setSelectAllCheckboxStatus(false);
    }

    const clearCheckboxStatus = () => {
        setSelectAllCheckboxStatus(false);
        setCheckboxStatus([]);
        props.setResultCheckboxes([]);
    }

    const updateCheckboxStatus = (rowId, status) => {
        const newValue  = { ...checkboxStatus, [rowId]: status }
        setCheckboxStatus(newValue);
        if(props.setResultCheckboxes) {
            props.setResultCheckboxes(newValue);
        }
    }

    const updateSelectAll = (status) => {
        if(!data && !data.result) {
            return;
        }

        // Determine new state
        status = !selectAllCheckboxStatus;

        // Set state
        setSelectAllCheckboxStatus(status);

        // Set the checkboxes of this page to this state
        let newCheckboxStatus = {};
        for(let i = 0; i < data.result.length; i++) {
            newCheckboxStatus[data.result[i].id] = status;
        }
        
        const newValue = { ...checkboxStatus, ...newCheckboxStatus };
        setCheckboxStatus(newValue);
        if(props.setResultCheckboxes) {
            props.setResultCheckboxes(newValue);
        }
    }

    useEffect(() => {
        // Take filter from query string
        for(let i = 0; i < props.columns.length; i++) {
            const filterName = props.columns[i].id;
            let filterValue = null;
            if (props.columns[i]?.filterMultiple === true) {
                filterValue = searchParams.getAll(filterName);
                if (filterValue.length > 0) {
                    setFilter(filter => ({ ...filter, [filterName]: filterValue }));
                }
            }
            else if (props.columns[i]?.filter !== false) {
                filterValue = searchParams.get(filterName);
                if (filterValue) {
                    setFilter(filter => ({ ...filter, [filterName]: filterValue }));
                }
            }
        }
    }, [searchParams, props.columns]);

    useEffect(() => {
        setTableBusy(true);

        // Setup query string parameters
        let params = {};

        // Ordering
        if(sortingOrderAscending !== false || sortingColumn !== 'date_modified') 
        {
            let orderingParameter = sortingColumn;
            if(orderingParameter === 'chapter') {
                orderingParameter = 'study__chapter';
            }

            if(['classification', 'extension', 'sensor', 'zone', 'study', 'study__chapter'].includes(orderingParameter)) {
                orderingParameter += '__name';
            }

            if(sortingOrderAscending === false) {
                orderingParameter = '-' + orderingParameter;
            }
            params.ordering = orderingParameter;
        }

        // Keyword search query
        if(typeof(searchQuery) === 'string' && searchQuery.length > 0) {
            params.search = searchQuery;
        }

        // Offset (current page)
        if(currentPage > 1) {
            params.offset = (currentPage - 1) * itemsPerPage;
        }

        // Results per page
        params.limit = itemsPerPage;

        // Add the "unpublished" flag if requested
        if(props.unpublished) {
            params.also_unpublished = 'true';
        }

        // Add filters to params
        for (const key in filter) {
            let filterKey = '';
            if(key === 'chapter') {
                filterKey = 'study__chapter';
            }
            else {
                filterKey = key;
            }

            let filterValue = filter[key];
            if(Array.isArray(filterValue)) {
                params[filterKey] = filterValue;
            }
            else {
                if(filterValue === false || filterValue === true) {
                    params[filterKey] = filterValue.toString();
                }
                else {
                    filterValue = parseInt(filterValue)
                    if(filterValue) {
                        params[filterKey] = filterValue;
                    }
                }
            }
        }

        if(props.refreshData === true) {
            props.setRefreshData(false);
        }

        if(props.resetCheckboxes === true) {
            props.setResetCheckboxes(false);
            clearCheckboxStatus();
        }

        if(props.filter !== null && typeof(props.filter) === 'object' && Object.keys(props.filter).length > 0) {
            params = { ...params, ...props.filter };
        }

        // Call endpoint to retrieve data
        retrieveData(props.endpoint, params, (data) => {
            setTableBusy(false);
            setData(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetCheckboxes, props.setResetCheckboxes, props.refreshData, props.setRefreshData, filter, currentPage, searchQuery, sortingColumn, sortingOrderAscending, props.endpoint, props.unpublished, props.filter]);

    let countCheckedCheckboxes = 0;
    for (const [key, value] of Object.entries(checkboxStatus)) {
        if(key && value === true) {
            countCheckedCheckboxes++;
        }
    }

    return (
        <>
            <ResultHeader
                controlItems={props.controlItems}
                updateSearchQuery={updateSearchQuery}
                setModalFilterShow={setModalFilterShow}
                setModalSortShow={setModalSortShow}
                filter={filter}
            />

            <ResultBody
                tableBusy={tableBusy}
                columns={props.columns}
                data={data}
                bulkActions={props.bulkActions}

                updateSort={updateSort}
                sortingColumn={sortingColumn}
                sortingOrderAscending={sortingOrderAscending}

                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                updateCurrentPage={updateCurrentPage}

                selectAllCheckboxStatus={selectAllCheckboxStatus}
                setSelectAllCheckboxStatus={setSelectAllCheckboxStatus}
                updateSelectAll={updateSelectAll}
                checkboxStatus={checkboxStatus}
                updateCheckboxStatus={updateCheckboxStatus}

                countCheckedCheckboxes={countCheckedCheckboxes}
            />

            <ModalFilter
                unpublished={props.unpublished}
                modalFilterShow={modalFilterShow}
                setModalFilterShow={setModalFilterShow}
                filter={filter}
                updateFilter={updateFilter}

                columns={props.columns}
            />

            <ModalSort
                modalSortShow={modalSortShow}
                setModalSortShow={setModalSortShow}
                columns={props.columns}

                updateSort={updateSort}
                sortingColumn={sortingColumn}
                sortingOrderAscending={sortingOrderAscending}
            /> 
        </>
    );
}
  
export default Result;
