import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiWindTurbine, mdiPlus, mdiPencil, mdiContentSave, mdiCancel } from '@mdi/js';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';

import { API_ENDPOINT_ZONES } from '../../const.js';
import { retrieveData, saveData, deleteData } from '../../data.js';
import { StateContext } from '../../App';
import { GenericDetailScreen, GenericDetailError } from '../Generic.js';
import Layout from '../Layout';
import Result from '../Result/Result';
import PageHeader from '../PageHeader';
import IconButtonSet from '../IconButtonSet';
import IconButton from '../IconButton';
import LoadingSpinner from '../LoadingSpinner.js';
import Published from '../Published';
import FormattedDate from '../FormattedDate';
import FormattedNumber from '../FormattedNumber';

export const Zones = () => {
    const { t } = useTranslation();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [checkedIds, setCheckedIds] = useState(false);
    const [resetCheckboxes, setResetCheckboxes] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const { locale, setAlert, setModalText, setShowModal, setShowModalConfirm, setModalConfirmData } = useContext(StateContext);

    const columns = [
        { id: 'id', type: 'checkbox', sort: false, filter: false },
        { id: 'name', route: `/${locale}/admin/zones/:id/view`, caption: t('Name'), width: '75%', class: 'width-50p-sm', filter: false },
        { id: 'published', type: 'boolean', width: '7em', caption: t('Published') },
        { id: 'date_modified', type: 'datetime', caption: t('Updated'), width: '8em', class: 'hide-lg', filter: false },
        { id: 'actions', type: 'actions', caption: t('Actions'),
            actions: [
                {type: 'view', title: t('View zone'), route: `/${locale}/admin/zones/:id/view`},
                {type: 'edit', title: t('Edit zone'), route: `/${locale}/admin/zones/:id/edit`},
                {type: 'delete', title: t('Delete zone'), onClick: (event) => { deleteZone(event) }}
            ],
            sort: false,
            filter: false,
        },
    ];

    const controlItems = [
        'search',
        'filter',
        'sort',
        {
            type: 'button',
            mdiIcon: mdiPlus,
            caption: t('New Zone'),
            variant: 'success',
            route: `/${locale}/admin/zones/new`,
        }
    ]

    const bulkActions = [
        { id: 'unpublish', caption: t('Unpublish'), onClick: () => unpublishMultipleZones() },
        { id: 'delete', caption: t('Delete'), onClick: () => deleteMultipleZones() },
    ];

    const setResultCheckboxes = (checkboxStatus) => {
        let arrayId = [];
        for (const [key, value] of Object.entries(checkboxStatus)) {
            if(value === true) {
                arrayId.push(key);
            }
        }
        setCheckedIds(arrayId);
    }

    const modalConfirmCallback = (type) => {
        if(type === 'delete') {
            deleteConfirmed();
        }
        else if(type === 'unpublish') {
            unpublishConfirmed();
        }
    }

    const unpublishMultipleZones = () => {
        if(checkedIds.length > 0) {
            unpublishShowConfirmation({id: checkedIds});
        }
    }

    const unpublishShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);

        let title = null;
        let text = null;
        if(!Array.isArray(rowData.id)) {
            return false;
        }

        title = t('Unpublish zones');
        if(rowData.id.length === 1) {
            text = t('Are you sure you want to unpublish 1 zone?');
        }
        else {
            text = t('Are you sure you want to unpublish {{num}} zones?', { num: rowData.id.length });
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Unpublish'), type: 'unpublish' });
        setShowModalConfirm(true);
    }

    const unpublishConfirmed = () => {
        const arrayFileIds = selectedRowData.id;      // Contains an array of file ids
        if(!Array.isArray(arrayFileIds) || arrayFileIds.length === 0) {
            return;
        }

        saveData(API_ENDPOINT_ZONES, null, { ids: arrayFileIds }, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                const numberOfUnpublishedFiles = data?.data['204'] || 0;
                if(numberOfUnpublishedFiles === 1) {
                    setAlert({text: t('Operation completed: 1 zone unpublished.')});
                }
                else {
                    setAlert({text: t('Operation completed: {{num}} zones unpublished.', { num: numberOfUnpublishedFiles })});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                setModalText(t('Could not unpublish the selected zones. The system returned the following error message: {{error}}', { error: data.error }));
                setShowModal(true);
            }
        }, 'PUT');
    }

    const deleteMultipleZones = () => {
        if(checkedIds.length > 0) {
            deleteShowConfirmation({id: checkedIds});
        }
    }

    const deleteZone = (event) => {
        const zoneId = event.currentTarget.parentNode.getAttribute('data-rec-id');
        const zoneName = event.currentTarget.parentNode.getAttribute('data-rec-name');
        if(zoneId <= 0) {
            return;
        }

        const rowData = { id: zoneId, name: zoneName };
        deleteShowConfirmation(rowData);
    }

    const deleteShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);
        let title = null;
        let text = null;
        if(Array.isArray(rowData.id)) {
            title = t('Delete zones');
            if(rowData.id.length === 1) {
                text = t('Are you sure you want to delete 1 zone?');
            }
            else {
                text = t('Are you sure you want to delete {{num}} zones?', { num: rowData.id.length });
            }
        }
        else {
            title = t('Delete zone');
            text = t('Are you sure you want to delete zone {{name}} (#{{id}})?', rowData);    
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Delete'), type: 'delete' });
        setShowModalConfirm(true);
    }

    const deleteConfirmed = () => {
        const zoneId = selectedRowData.id;
        const zoneName = selectedRowData.name;
        if(zoneId <= 0) {
            return;
        }

        deleteData(API_ENDPOINT_ZONES, zoneId, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                if(Array.isArray(selectedRowData.id)) {
                    // Multiple items
                    const numberOfDeletedZones = data?.data['204'] || 0;
                    const numberOfFailedZones = data?.data['409'] || 0;
                    let textKey = null;
                    if(numberOfFailedZones > 0) {
                        textKey = 'Operation completed: {{numDeleted}} zone(s) successfully deleted, {{numFailed}} zone(s) could not be deleted as one or more studies link to them.';
                    }
                    else if(numberOfDeletedZones === 1) {
                        textKey = 'Operation completed: 1 zone successfully deleted.';
                    }
                    else {
                        textKey = 'Operation completed: {{numDeleted}} zone(s) successfully deleted.';
                    }
                    setAlert({ text: t(textKey, { numDeleted: numberOfDeletedZones, numFailed: numberOfFailedZones }) });
                }
                else {
                    // Single item
                    setAlert({text: t('Zone {{name}} (#{{id}}) is successfully deleted.', selectedRowData)});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                let textKey = null;
                if(Array.isArray(selectedRowData.id)) {
                    textKey = 'Could not delete the selected zone(s). The system returned the following error message: {{error}}';
                }
                else {
                    if(data.status === 409) {
                        textKey = 'Could not delete zone {{name}} (#{{id}}), because at least one study links to it.';
                    }
                    else {
                        textKey = 'Could not delete zone {{name}} (#{{id}}). The system returned the following error message: {{error}}';
                    }
                }
                setModalText(t(textKey, { id: zoneId, name: zoneName, error: data.message }));
                setShowModal(true);
            }
        });
    }

    return (
        <Layout modalConfirmCallback={modalConfirmCallback}>
            <PageHeader mdiIcon={mdiWindTurbine} path={[{caption: t('Admin Panel'), route: `/${locale}/admin`}]}>{t('Zones')}</PageHeader>
            <Result
                endpoint={API_ENDPOINT_ZONES}
                unpublished={true}
                columns={columns}
                controlItems={controlItems}
                bulkActions={bulkActions}
                setResultCheckboxes={setResultCheckboxes}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                resetCheckboxes={resetCheckboxes}
                setResetCheckboxes={setResetCheckboxes}
            />
        </Layout>
    )
}

const getZone = (zoneId, callback) => {
    zoneId = parseInt(zoneId);
    if(zoneId > 0) {
        const url = API_ENDPOINT_ZONES + zoneId + '/';
        retrieveData(url, { 'also_unpublished': 'true' }, (data) => {
            if(typeof(callback) === 'function') {
                callback(data);
            }
        });
    }
    else {
        // Don't bother trying to retrieve a record for an ID <= 0, immediately return 404
        if(typeof(callback) === 'function') {
            callback({result: null, success: false, status: 404});
        }
    }
}

export const ZonesEdit = (props) => {
    const { zoneId } = useParams();
    const action = props.action;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale, setModalText, setShowModal } = useContext(StateContext);

    const [formIsDisabled, setFormIsDisabled] = useState(zoneId > 0 ? true : false);
    const [formIsSaving, setFormIsSaving] = useState(false);
    const [data, setData] = React.useState(null);

    const [showTooltipName, setShowTooltipName] = useState(false);

    const [zoneName, setZoneName] = React.useState('');
    const [zonePublished, setZonePublished] = React.useState(false);

    const targetName = useRef(null);
    const targetNameLabel = useRef(null);

    const screenData = {
        title: action === 'edit' && zoneId > 0 ? t('Edit zone') : t('New zone'),
        subTitle: zoneId > 0 ? t('Zone (#{{id}})', { id: zoneId}) : t('Zone'),
        mdiIcon: mdiWindTurbine,
        path: [
            {caption: t('Zones'), route: `/${locale}/admin/zones`},
        ],
    }

    const submitForm = (event, zoneId) => {
        event.preventDefault();
        
        // Validation
        if(zoneName.length === 0) {
            // Name is required
            setShowTooltipName(true);
            targetName.current.focus();
            return false;
        }
    
        // Disable form and show spinner
        setFormIsDisabled(true);
        setFormIsSaving(true);
    
        // Setup payload
        const payload = {
            name: zoneName.trim(),
            published: !!zonePublished,
        };
    
        // Send data
        saveData(API_ENDPOINT_ZONES, zoneId, payload, (response) => {
            // Enable form and hide spinner
            setFormIsDisabled(false);
            setFormIsSaving(false);

            if(response.success === true) {
                // Success
                if(!zoneId) {
                    zoneId = response.data.id;
                }

                // Navigate to detail view
                navigate(`/${locale}/admin/zones/${zoneId}/view`);
            }
            else {
                // Failure
                setModalText(response?.error?.message ?? 'Error');
                setShowModal(true);
            }    
        });
    }

    const handleChangeZoneName = (event) => {
        setZoneName(event.target.value);
        setShowTooltipName(false);
    }

    const handleChangeZonePublished = (event) => {
        setZonePublished(!!event.target.checked);
    }

    useEffect(() => {
        if(action === 'edit') {
            getZone(zoneId, (data) => {
                setData(data);
                if(data.success === true) {
                    // Set form values
                    setZoneName(data.result?.name);
                    setZonePublished(!!data.result?.published);
                }

                // Enable form 
                setFormIsDisabled(false);
            });
        }
        else {
            setData({ success: true });
        }
    }, [action, zoneId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    if(data.success === true) {
        return (
            <GenericDetailScreen data={screenData}>
                <Form onSubmit={event => submitForm(event, zoneId)} name="zoneForm">
                    <fieldset disabled={formIsDisabled}>
                        <FloatingLabel controlId="zoneName" ref={targetNameLabel} label={t('Name')} className="mb-4">
                            <Form.Control
                                ref={targetName}
                                type="text"
                                maxLength="100"
                                value={zoneName}
                                placeholder={t('Zone name')}
                                onChange={handleChangeZoneName}
                                onBlur={() => { setShowTooltipName(false) }}
                                autoFocus
                            />
                        </FloatingLabel>
                        <Overlay target={targetNameLabel.current} show={showTooltipName} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please enter a zone name.')}
                            </Tooltip>
                        </Overlay>

                        <div className="mb-4">
                            <Form.Switch 
                                defaultChecked={zonePublished}
                                onChange={handleChangeZonePublished}
                                id="zonePublished"
                                label={t('Zone is published to website')}
                            />
                        </div>

                        <hr />

                        <IconButtonSet showSpinner={formIsSaving}>
                            <IconButton mdiIcon={mdiContentSave} onClick={(event) => submitForm(event, zoneId)}>{t('Save')}</IconButton>
                            <IconButton mdiIcon={mdiCancel} variant="primary" route={`/${locale}/admin/zones`}>{t('Cancel')}</IconButton>
                        </IconButtonSet>
                    </fieldset>
                </Form>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <ZonesDetailError data={data} />
        </GenericDetailScreen>
    )
}

export const ZonesView = (props) => {
    const { zoneId } = useParams();
    const { t } = useTranslation();
    const [data, setData] = React.useState(null);
    const { locale } = useContext(StateContext);

    const screenData = {
        title: t('View zone'),
        subTitle: zoneId > 0 ? t('Zone (#{{id}})', { id: zoneId}) : t('Zone'),
        mdiIcon: mdiWindTurbine,
        path: [
            { caption: t('Zones'), route: `/${locale}/admin/zones` },
        ],
        backButton: true,
    }

    useEffect(() => {
        getZone(zoneId, data => setData(data));
    }, [zoneId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    if(data.success === true) {
        return (
            <GenericDetailScreen data={screenData}>
                <Table bordered striped responsive className="detailTable mb-4">
                    <tbody>
                        <tr>
                            <th>{t('ID')}</th>
                            <td>#{data.result?.id}</td>
                        </tr>
                        <tr>
                            <th>{t('Name')}</th>
                            <td>{data.result?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Number of studies')}</th>
                            <td><FormattedNumber>{data.result?.nr_studies}</FormattedNumber></td>
                        </tr>
                        <tr>
                            <th>{t('Published')}</th>
                            <td><Published value={data.result?.published} /></td>
                        </tr>
                        <tr>
                            <th>{t('Created')}</th>
                            <td><FormattedDate format="full">{data.result?.date_created}</FormattedDate></td>
                        </tr>
                        <tr>
                            <th>{t('Updated')}</th>
                            <td><FormattedDate format="full">{data.result?.date_modified}</FormattedDate></td>
                        </tr>
                    </tbody>
                </Table>

                <IconButtonSet>
                    <IconButton mdiIcon={mdiPencil} route={`/${locale}/admin/zones/${zoneId}/edit`}>{t('Edit zone')}</IconButton>
                </IconButtonSet>
            </GenericDetailScreen>
        )
    }

    return (
        <GenericDetailScreen data={screenData}>
            <ZonesDetailError data={data} />
        </GenericDetailScreen>
    )
}

const ZonesDetailError = (props) => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);

    return (
        <GenericDetailError
            data={props.data}
            messageNotFound={t('This zone does not exist (anymore).')}
            buttons={
                [
                    { mdiIcon: mdiPencil, route: `/${locale}/admin/zones/new`, caption: t('New zone') }
                ]
            }
        />
    )
}
