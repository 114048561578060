import React  from 'react';

import ResultCheckbox from './ResultCheckbox';
import ResultColumnActions from './ResultColumnActions'
import CellValue from '../CellValue';

const ResultCell = (props) => {
    if(props.column.type === 'checkbox') {
        return <ResultCheckbox
            rowId={props.rowId}
            rowName={props.rowName}
            rowCheckboxStatus={props.rowCheckboxStatus}
            updateCheckboxStatus={props.updateCheckboxStatus}
            setSelectAllCheckboxStatus={props.setSelectAllCheckboxStatus}
        />;
    }
    else if(props.column.type === 'actions') {
        return <ResultColumnActions
            value={props.value}
            column={props.column}
            rowId={props.rowId}
            rowName={props.rowName}
            renameInProgress={props.renameInProgress}
        />
    }

    return <CellValue value={props.value} column={props.column} rowId={props.rowId} renameInProgress={props.renameInProgress} />;
}
  
export default ResultCell;
