export const DEFAULT_LANGUAGE = 'en';

export const BASE_URL = window.location.hostname === 'localhost' && window.location.port === '3000' ? 'http://localhost:8000' : '';

export const OIDC_URL_LOGIN = BASE_URL + '/api/oidc/authenticate/';
export const OIDC_URL_LOGOUT = BASE_URL + '/api/oidc/logout/';

export const API_BASE_URL = BASE_URL + '/api/v1/';
export const API_ENDPOINT_FILES = API_BASE_URL + 'files/';
export const API_ENDPOINT_ZONES = API_BASE_URL + 'zones/';
export const API_ENDPOINT_STUDIES = API_BASE_URL + 'studies/';
export const API_ENDPOINT_SENSORS = API_BASE_URL + 'sensors/';
export const API_ENDPOINT_CLASSIFICATIONS = API_BASE_URL + 'classifications/';
export const API_ENDPOINT_CHAPTERS = API_BASE_URL + 'chapters/';
export const API_ENDPOINT_EXTENSIONS = API_BASE_URL + 'extensions/';
export const API_ENDPOINT_USERS_ME = API_BASE_URL + 'users/me/';
export const API_ENDPOINT_TOTALS = API_BASE_URL + 'totals/';
export const API_ENDPOINT = {
    file: API_ENDPOINT_FILES,
    zone: API_ENDPOINT_ZONES,
    study: API_ENDPOINT_STUDIES,
    sensor: API_ENDPOINT_SENSORS,
    classification: API_ENDPOINT_CLASSIFICATIONS,
    chapter: API_ENDPOINT_CHAPTERS,
    extension: API_ENDPOINT_EXTENSIONS,
};

export const AZCOPY_URL = 'https://{{AZURE_ACCOUNT_NAME}}.blob.core.windows.net/{{AZURE_CONTAINER_NAME}}/{{YEAR}}/{{MONTH}}/';
export const AZCOPY_PARAMS = '--put-md5';
export const AZCOPY_COMMAND_UPLOAD_MULTIPLE_WINDOWS = 'for %F in ({{FILES}}) do azcopy copy %F ' + AZCOPY_URL + ' ' + AZCOPY_PARAMS;
export const AZCOPY_COMMAND_UPLOAD_SINGLE_WINDOWS = 'azcopy copy {{FILES}} ' + AZCOPY_URL + ' ' + AZCOPY_PARAMS;
export const AZCOPY_COMMAND_UPLOAD_MULTIPLE_MACOS_LINUX = 'for F in {{FILES}}; do azcopy copy $F ' + AZCOPY_URL + ' ' + AZCOPY_PARAMS;
export const AZCOPY_COMMAND_UPLOAD_SINGLE_MACOS_LINUX = 'azcopy copy {{FILES}} ' + AZCOPY_URL + ' ' + AZCOPY_PARAMS;
export const AZCOPY_COMMAND_LOGIN = 'azcopy login';

export const COPY_TEXT_DURATION = 3000; // milliseconds

export const ENVIRONMENT = document.body.getAttribute('data-environment');
export const USER_EXTERNAL_ID = document.body.getAttribute('data-user-external-id');
export const DEBUG_SIMULATE_LOGIN = (ENVIRONMENT === 'development' || ENVIRONMENT === '{{ ENVIRONMENT }}') && window.location.hostname === 'localhost' && window.location.port === '3000';
