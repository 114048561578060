import React  from 'react';
import { ResultColumnActionsItem } from './ResultColumnActionsItem';

const ResultColumnActions = (props) => {
    let actions = props.column.actions;
    return (
        <div className="rowActions">
            {
                actions.map((action, i) => 
                    <ResultColumnActionsItem key={i} action={action} rowId={props.rowId} rowName={props.rowName} iterator={i} renameInProgress={props.renameInProgress} />
                )
            }
        </div>
    )
}
  
export default ResultColumnActions;
