import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

function DismissableAlert(props) {
    const [show, setShow] = useState(true);

    if(show) {
        return (
            <Alert variant={props.variant ?? 'danger'} onClose={() => setShow(false)} dismissible aria-live="polite">
                {props.children}
            </Alert>
        );
    }
}

export default DismissableAlert;
