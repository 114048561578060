import React  from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Icon from '@mdi/react'

const PageHeader = (props) => {
    return (
        <>
            <h1><Icon path={props.mdiIcon} size={1.5} aria-hidden={true} />{props.children}</h1>
            {props.path && props.path.length &&
                <Breadcrumb className="navBreadcrumb area">
                    { props.path.map((item, i) => <Breadcrumb.Item key={i} href={item.route}>{item.caption}</Breadcrumb.Item>) }
                    <Breadcrumb.Item active={true}>{props.children}</Breadcrumb.Item>
                </Breadcrumb>
            }
        </>
    )
}
  
export default PageHeader;
