import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import Published from './Published';
import FileSize from './FileSize';
import FormattedDate from './FormattedDate';
import FormattedNumber from './FormattedNumber';

export const CellValue = (props) => {
    let value = null;
    if(typeof(props.value) === 'undefined' || props.value === null) {
        return null;
    }
    else if(Array.isArray(props.value)) {
        let result = [];
        for(let i = 0; i < props.value.length; i++) {
            result.push(props.column?.key ? props.value[i][props.column.key] : props.value[i]);
        }
        value = result.join(', ');
    }
    else if(typeof(props.value) === 'object') {
        value = props.column?.key ? props.value[props.column.key] : null;
    }
    else {
        value = props.value;
    }

    let result = null;
    if(props.column?.type === 'boolean' && props.column.id === 'published') {
        result = <Published value={value} />;
    }
    else if (props.column?.type === 'filename') {
        result = <>{value}{props.renameInProgress && <Spinner animation="border" size="sm" className="ms-2"/>}</>
    }
    else if(props.column?.type === 'filesize') {
        result = <FileSize>{value}</FileSize>
    }
    else if(props.column?.type === 'datetime') {
        result = <FormattedDate format="short">{value}</FormattedDate>
    }
    else if(props.column?.type === 'number') {
        result = <FormattedNumber>{value}</FormattedNumber>
    }
    else {
        result = value;
    }

    if(props.column?.route) {
        let route = props.column.route.replaceAll(':id', '' + props.rowId);
        return <Link to={route}>{result}</Link>
    }
    else {
        return result;
    }
}

export default CellValue;
