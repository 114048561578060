import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

export const LoadingSpinner = () => {
    const { t } = useTranslation();
    return (
        <div className="loadingSpinner">
            <div className="container">
                <Spinner animation="border" size="lg" />
                <div className="caption">{t('Loading data')}</div>
            </div>
        </div>
    );
}

export default LoadingSpinner;
