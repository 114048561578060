import CopyInput from './CopyInput.js';

const FormattedMD5Hash = (props) => {
    const md5HashHex = props.children;
    if(!md5HashHex) {
        return '-';
    }
    // https://stackoverflow.com/questions/23190056/hex-to-base64-converter-for-javascript
    const md5HashBase64 = btoa(md5HashHex.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(''));
    return (<>
        <div class="copyClipboardGroup">
            <CopyInput label="Hex">{md5HashHex}</CopyInput>
            <CopyInput label="Base64">{md5HashBase64}</CopyInput>
        </div>
    </>);
}
  
export default FormattedMD5Hash;
