import React  from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Icon from '@mdi/react'

const IconButton = (props) => {
    const navigate = useNavigate(); 

    const variant = props.variant ?? 'primary';
    const mdiIcon = props.mdiIcon;
    const size = props.size ?? 0.75;
    const color = props.color ?? '#fff';
    const caption = props.children || props.caption || '';
    const ariaLabel = props['aria-label'];

    const routeChange = (path) => {
        navigate(path);
    }

    const route = props.route ?? '/';
    const onClick = props.onClick ?? (() => { routeChange(route) });

    return (
        <Button aria-label={ariaLabel} disabled={props.disabled} variant={variant} as="button" onClick={onClick} className="iconButton" title={props.tooltip}>
            <span className="icon"><Icon path={mdiIcon} size={size} color={color} aria-hidden={true} /></span>
            <span className="caption">{caption}</span>
        </Button>
    );
}
  
export default IconButton;
