import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { DEFAULT_LANGUAGE, API_ENDPOINT_USERS_ME, DEBUG_SIMULATE_LOGIN, USER_EXTERNAL_ID } from './const.js';
import { retrieveData } from './data.js';
import { Explorer, ExplorerDetail } from './components/Explorer';
import { Zones, ZonesEdit, ZonesView } from './components/Admin/Zones';
import { Studies, StudiesEdit, StudiesView } from './components/Admin/Studies';
import { Files, FilesEdit, FilesView } from './components/Admin/Files';
import { UnprocessedFiles, UnprocessedFilesEdit, UnprocessedFilesView } from './components/Admin/UnprocessedFiles';
import LoadingSpinner from './components/LoadingSpinner.js';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import LoginFailure from './components/LoginFailure';
import Logout from './components/Logout';
import Profile from './components/Profile';
import Help from './components/Help';
import Dashboard from './components/Admin/Dashboard';
import Upload from './components/Admin/Upload';
import PageNotFound from './components/PageNotFound';

export const UserAccountContext = React.createContext(null);
export const StateContext = React.createContext({});

const App = () => {
    const [userAccount, setUserAccount] = useState(null);
    const { i18n } = useTranslation();
    const [alert, setAlert] = useState({});
    const [locale, setLocale] = useState();
    const [modalText, setModalText] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalConfirmData, setModalConfirmData] = useState({ title: null, text: null});

    useEffect(() => {
        let currentLocale = null;
        if(!locale) {
            // Determine current language
            const pathName = document.location.pathname;
            if(pathName.substring(0, 4) === '/nl/' || pathName === '/nl')  {
                currentLocale = 'nl';
            }
            else if(pathName.substring(0, 4) === '/en/' || pathName === '/en')  {
                currentLocale = 'en';
            }
            else {
                const storedLocale = localStorage.getItem('locale');
                if(storedLocale === 'nl' || storedLocale === 'en') {
                    currentLocale = storedLocale;
                }
                else {
                    currentLocale = DEFAULT_LANGUAGE;
                }
            }

            // Set locale
            setLocale(currentLocale);
        }
        else {
            currentLocale = locale;
        }

        // Store locale in local storage
        localStorage.setItem('locale', currentLocale);

        // Change language
        i18n.changeLanguage(currentLocale);

        // Set lang attribute of <html> tag
        document.documentElement.lang = currentLocale;

        // Debug: simulate login on port 3000
        if(DEBUG_SIMULATE_LOGIN) {
            setUserAccount({
                external_id: 1001,
                email: 'test1@user.com',
                name: 'Test User',
                is_staff: true,
            });
            return;
        }

        if(USER_EXTERNAL_ID) {
            // Retrieve user data
            retrieveData(API_ENDPOINT_USERS_ME, null, (data) => {
                if(data.success === true) {
                    // User is logged in
                    setUserAccount(data.result);
                }
                else {
                    // User is not logged in
                    setUserAccount(false);
                }
            });
        }
        else {
            // User is not logged in
            setUserAccount(false);
        } 
    }, [i18n, locale]);

    if(userAccount === null) {
        return (<LoadingSpinner />);
    }

    return (
        <UserAccountContext.Provider value={userAccount}>
            <StateContext.Provider value={{
                locale, setLocale,
                alert, setAlert,
                modalText, setModalText,
                showModal, setShowModal,
                showModalConfirm, setShowModalConfirm,
                modalConfirmData, setModalConfirmData
            }}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Login setUserAccount={setUserAccount} />} path={'/en/login'} />
                        <Route element={<Login setUserAccount={setUserAccount} />} path={'/nl/login'} />
                        <Route element={<LoginFailure />} path={'/login/failure'} />
                        <Route element={<Logout setUserAccount={setUserAccount} />} path={'/en/logout'} />
                        <Route element={<Logout setUserAccount={setUserAccount} />} path={'/nl/logout'} />
                        <Route element={<Profile />} path={'/en/profile'} />
                        <Route element={<Profile />} path={'/nl/profile'} />
                        
                        <Route element={<Explorer />} path={'/'} />
                        <Route element={<Explorer />} path={'/en'} />
                        <Route element={<Explorer />} path={'/nl'} />
                        <Route element={<Explorer />} path={'/en/explorer'} />
                        <Route element={<Explorer />} path={'/nl/explorer'} />
                        <Route element={<ExplorerDetail />} path={'/en/explorer/:fileId'} />
                        <Route element={<ExplorerDetail />} path={'/nl/explorer/:fileId'} />
                        <Route element={<Help />} path={'/en/help'} />
                        <Route element={<Help />} path={'/nl/help'} />

                        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path={'/en/admin'} />
                        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path={'/nl/admin'} />
                        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path={'/en/admin/dashboard'} />
                        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path={'/nl/admin/dashboard'} />
                        <Route element={<PrivateRoute><Upload /></PrivateRoute>} path={'/en/admin/upload'} />
                        <Route element={<PrivateRoute><Upload /></PrivateRoute>} path={'/nl/admin/upload'} />

                        <Route element={<PrivateRoute><Zones /></PrivateRoute>} path={'/en/admin/zones'} />
                        <Route element={<PrivateRoute><Zones /></PrivateRoute>} path={'/nl/admin/zones'} />
                        <Route element={<PrivateRoute><ZonesEdit action="new" /></PrivateRoute>} path={'/en/admin/zones/new'} />
                        <Route element={<PrivateRoute><ZonesEdit action="new" /></PrivateRoute>} path={'/nl/admin/zones/new'} />
                        <Route element={<PrivateRoute><ZonesEdit action="edit" /></PrivateRoute>} path={'/en/admin/zones/:zoneId/edit'} />
                        <Route element={<PrivateRoute><ZonesEdit action="edit" /></PrivateRoute>} path={'/nl/admin/zones/:zoneId/edit'} />
                        <Route element={<PrivateRoute><ZonesView /></PrivateRoute>} path={'/en/admin/zones/:zoneId/view'} />
                        <Route element={<PrivateRoute><ZonesView /></PrivateRoute>} path={'/nl/admin/zones/:zoneId/view'} />

                        <Route element={<PrivateRoute><Studies /></PrivateRoute>} path={'/en/admin/studies'} />
                        <Route element={<PrivateRoute><Studies /></PrivateRoute>} path={'/nl/admin/studies'} />
                        <Route element={<PrivateRoute><StudiesEdit action="new" /></PrivateRoute>} path={'/en/admin/studies/new'} />
                        <Route element={<PrivateRoute><StudiesEdit action="new" /></PrivateRoute>} path={'/nl/admin/studies/new'} />
                        <Route element={<PrivateRoute><StudiesEdit action="edit" /></PrivateRoute>} path={'/en/admin/studies/:studyId/edit'} />
                        <Route element={<PrivateRoute><StudiesEdit action="edit" /></PrivateRoute>} path={'/nl/admin/studies/:studyId/edit'} />
                        <Route element={<PrivateRoute><StudiesView /></PrivateRoute>} path={'/en/admin/studies/:studyId/view'} />
                        <Route element={<PrivateRoute><StudiesView /></PrivateRoute>} path={'/nl/admin/studies/:studyId/view'} />
                        
                        <Route element={<PrivateRoute><Files /></PrivateRoute>} path={'/en/admin/files'} />
                        <Route element={<PrivateRoute><Files /></PrivateRoute>} path={'/nl/admin/files'} />
                        <Route element={<PrivateRoute><FilesEdit action="edit" /></PrivateRoute>} path={'/en/admin/files/:fileId/edit'} />
                        <Route element={<PrivateRoute><FilesEdit action="edit" /></PrivateRoute>} path={'/nl/admin/files/:fileId/edit'} />
                        <Route element={<PrivateRoute><FilesView /></PrivateRoute>} path={'/en/admin/files/:fileId/view'} />
                        <Route element={<PrivateRoute><FilesView /></PrivateRoute>} path={'/nl/admin/files/:fileId/view'} />

                        <Route element={<PrivateRoute><UnprocessedFiles /></PrivateRoute>} path={'/en/admin/files/unprocessed'} />
                        <Route element={<PrivateRoute><UnprocessedFiles /></PrivateRoute>} path={'/nl/admin/files/unprocessed'} />
                        <Route element={<PrivateRoute><UnprocessedFilesEdit action="edit" /></PrivateRoute>} path={'/en/admin/files/unprocessed/:fileId/edit'} />
                        <Route element={<PrivateRoute><UnprocessedFilesEdit action="edit" /></PrivateRoute>} path={'/nl/admin/files/unprocessed/:fileId/edit'} />
                        <Route element={<PrivateRoute><UnprocessedFilesView /></PrivateRoute>} path={'/en/admin/files/unprocessed/:fileId/view'} />
                        <Route element={<PrivateRoute><UnprocessedFilesView /></PrivateRoute>} path={'/nl/admin/files/unprocessed/:fileId/view'} />

                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
            </StateContext.Provider>
        </UserAccountContext.Provider>
    );
}

export default App;
