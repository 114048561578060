import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { mdiFilePlus, mdiFileRemove, mdiFileUpload } from '@mdi/js';

import { GenericDetailScreen } from '../Generic.js';
import { AZCOPY_COMMAND_LOGIN, AZCOPY_COMMAND_UPLOAD_SINGLE_WINDOWS, AZCOPY_COMMAND_UPLOAD_SINGLE_MACOS_LINUX, AZCOPY_COMMAND_UPLOAD_MULTIPLE_WINDOWS, AZCOPY_COMMAND_UPLOAD_MULTIPLE_MACOS_LINUX } from '../../const.js';
import { StateContext } from '../../App';
import IconButton from '../IconButton';
import IconButtonSet from '../IconButtonSet';
import CopyInput from '../CopyInput.js';

const Upload = () => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);
    const textRef = useRef();
    const inputFileRef = useRef();
    const [input, setInput] = useState('');
    const [dragActive, setDragActive] = useState(false);

    // Make sure the input field for filenames has a fixed height
    useEffect(() => {
        if (textRef && textRef.current) {
          const taHeight = textRef.current.scrollHeight;
          textRef.current.style.height = taHeight + 'px';
        }
      }, [input]);

    const onFileInput = (event) => {
        setInput(event.target.value);
    };
    
    // Triggered when dragged file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        setDragActive(false);
        if(e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            addFiles(e.dataTransfer.files);
        }
    };
    
    // Handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        setDragActive(e.type === 'dragenter' || e.type === 'dragover');
    };

    // Triggered when file is selected with click
    const handleInputFileChange = function(e) {
        e.preventDefault();
        if(e.target.files && e.target.files.length > 0) {
            addFiles(e.target.files);
        }
    };

    // Add files to textarea
    const addFiles = (files) => {
        let arrayFiles = [];
        for(let i = 0; i < files.length; i++) {
            arrayFiles.push(files[i].name);
        }
        const newInput = arrayFiles.join("\n") + "\n";
        setInput(newInput);
    }

    // Triggers the input when the button is clicked
    const onSelectFileClick = () => {
        inputFileRef.current.click();
    };

    const onClearFileClick = () => {
        setInput('');
        textRef.current.style.height = '58px';
    };

    // Trim white space and replace newlines between filenames with a space
    // character, add double quotes around the filenames
    const inputFilesArray = input.trim().split(/\r?\n/);
    let files = '"' + inputFilesArray.join('" "') + '"';
    if(files === '""') {
        files = null;
    }

    // Add drag & drop events to window
    window.addEventListener('dragenter', handleDrag);
    window.addEventListener('dragleave', handleDrag);
    window.addEventListener('dragover', handleDrag);
    window.addEventListener('drop', handleDrop);
    
    // Get current year and month
    const year = new Date().getFullYear();
    const month = ('0' + (new Date().getMonth() + 1)).slice(-2);

    // Create login command
    const loginCommand = AZCOPY_COMMAND_LOGIN;

    // Create upload command
    const azureAccountName = document.body.getAttribute('data-azure-account-name');
    const azureContainerName = document.body.getAttribute('data-azure-container-name');
    let uploadCommand = {
        windows: inputFilesArray.length <= 1 ? AZCOPY_COMMAND_UPLOAD_SINGLE_WINDOWS : AZCOPY_COMMAND_UPLOAD_MULTIPLE_WINDOWS,
        macOSLinux: inputFilesArray.length <= 1 ? AZCOPY_COMMAND_UPLOAD_SINGLE_MACOS_LINUX : AZCOPY_COMMAND_UPLOAD_MULTIPLE_MACOS_LINUX,
    };
    for(const [key] of Object.entries(uploadCommand)) {
        uploadCommand[key] = uploadCommand[key].replace('{{AZURE_ACCOUNT_NAME}}', azureAccountName);
        uploadCommand[key] = uploadCommand[key].replace('{{AZURE_CONTAINER_NAME}}', azureContainerName);
        uploadCommand[key] = uploadCommand[key].replace('{{YEAR}}', year);
        uploadCommand[key] = uploadCommand[key].replace('{{MONTH}}', month);
        uploadCommand[key] = uploadCommand[key].replace('{{FILES}}', files ? files : 'ExampleFile.txt');
    }

    // Create screen data
    const screenData = {
        title: t('Upload Files'),
        subTitle: t('Upload instructions for AzCopy'),
        mdiIcon: mdiFileUpload,
        path: [
            {caption: t('Dashboard'), route: `/${locale}/admin/dashboard`},
        ],
    };

    // Render upload page
    return (
        <GenericDetailScreen data={screenData}>
            <h3>{t('Introduction')}</h3>
            <p><Trans i18nKey="uploadIntroduction">This page gives instructions on how to upload files to the storage location on Microsoft Azure. For this, we use <a href="https://learn.microsoft.com/en-us/azure/storage/common/storage-use-azcopy-v10" target="_blank" rel="noopener noreferrer">AzCopy</a>, a software utility provided by Microsoft. Instruction on how to download and install this utility can be found on the <Link to={`/${locale}/help`}>help page</Link>. The instructions below assume you have AzCopy installed and added to the system path.</Trans></p>

            <h3>{t('Logging in')}</h3>
            <p><Trans i18nKey="uploadLogin1">In order to upload files, AzCopy requires to be logged in. On Windows, open the Command Prompt (click Start, type "cmd" and press enter). On MacOS, open the Terminal application (Applications → Utilities → Terminal). Copy below command and paste it into the Command Prompt (or Terminal). </Trans></p>
            <div class="copyClipboardGroup mb-3">
                <CopyInput aria-label={t('AzCopy Login')}>{loginCommand}</CopyInput>
            </div>

            <p><Trans i18nKey="uploadLogin2">To log in, copy the authentication code as displayed in the Command Prompt (or Terminal), use your web browser to open <a href="https://microsoft.com/devicelogin" target="_blank" rel="noopener noreferrer">https://microsoft.com/devicelogin</a>, paste the code in the web browser and click "Next". After this, use your Microsoft Azure account to log in.</Trans></p>

            <h3>{t('Uploading')}</h3>
            <p><Trans i18nKey="uploadSelect">Once logged in, AzCopy can upload files to the Azure storage. Select files to upload by clicking the button below, or by dragging and dropping files onto this page. Please note that all files should be in the same, current directory.</Trans></p>
            <FloatingLabel label={t ('Filenames')} className="mb-2">
                <Form.Control
                    id="uploadFilenames"
                    as="textarea"
                    value={input}
                    ref={textRef}
                    onChange={onFileInput}
                    onClick={onSelectFileClick}
                    aria-label={t('Select Files')}
                    className={'createUploadInput' + (dragActive ? ' dragActive' : '')}
                    readOnly
                />
            </FloatingLabel>
            <div>
                <input ref={inputFileRef} type="file" id="inputFileUpload" multiple={true} onChange={handleInputFileChange} />
                <IconButtonSet>
                    <IconButton mdiIcon={mdiFilePlus} onClick={onSelectFileClick}>{t('Select Files')}</IconButton>
                    <IconButton mdiIcon={mdiFileRemove} onClick={onClearFileClick}>{t('Clear Files')}</IconButton>
                </IconButtonSet>
            </div>

            <p><Trans i18nKey="uploadCommand">Find the command to upload the selected files below. First make sure that the current directory contains the files to upload by going to the Command Prompt (or Terminal) and using the "dir" or "ls" command. If necessary, navigate to this directory using the "cd" (change directory) command. Once in the desired directory, copy the command below and paste it into the Command Prompt (or Terminal).</Trans></p>
            <div class="copyClipboardGroup mb-3">
                <CopyInput label="Windows">{uploadCommand.windows}</CopyInput>
                <CopyInput label="MacOS/Linux">{uploadCommand.macOSLinux}</CopyInput>
            </div>

            <p><Trans i18nKey="uploadResult">Once the process is completed, the file is uploaded to the Azure storage and it will show up under <Link to={`/${locale}/admin/files/unprocessed`}>Unprocessed Files</Link>.</Trans></p>
        </GenericDetailScreen>
    );
};

export default Upload;
